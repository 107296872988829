import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { Route, useNavigate } from 'react-router'
import { Navigate, Routes } from 'react-router-dom'
import { Login } from '../views/auth/Login'
import { UserStoreContext } from '../store/userStore'
import { DashboardView } from '../views/dashboard/DashboardView'
import { Events } from '../views/events/Events'
import { Portfolio } from '../views/portfolio/Portfolio'
import { Navigation } from './Navigation'
import { SelectDashboard } from '../views/dashboard/SelectDashboard'
import { EventsCreate } from '../views/events/EventsCreate'
import { EventsEdit } from '../views/events/EventsEdit'
import { Blog } from '../views/blog/Blog'
import { DocumentMain } from '../views/documents/DocumentMain'
import { Documents } from '../views/documents/Documents'
import { Statistics } from '../views/statistics/Statistics'
import { StatisticsDetail } from '../views/statistics/StatisticsDetail'
import { DocumentsFromType } from '../views/documents/DocumentsFromType'
import { Present } from '../views/present/Present'
import { OurKids } from '../views/ourKids/OurKids'
import { Contact } from '../views/contact/Contact'
import { Absence } from '../views/absence/Absence'
import { Documentation } from '../views/documentation/Documentation'
import { DocumentationViewer } from '../views/documentation/DocumentationViewer'
import { USER_ROLE_TYPE } from '../Enums/TyraEnums'
import { ContactLists } from '../views/contactlists/ContactLists'
import { Notes } from '../views/notes/Notes'
import { useIdleTimer } from 'react-idle-timer'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import { PdfBuilder } from '../views/pdfBuilder/PdfBuilder'
import { PDFExportRoutes, SkaExport } from '../views/skaExport/skaExport'
import { Utbildning } from '../views/skaExport/Utbildning'
import { Undervisning } from '../views/skaExport/Undervisning'
import { Barndokumentation } from '../views/skaExport/Barndokumentation'
import Admin from '../views/admin/adminDashboard/Admin'
import { Child } from '../views/admin/child/Child'
import ChangeChildDep from '../views/admin/child/components/ChangeChildDep'
import ChangeChildPSU from '../views/admin/child/components/ChangeChildPSU'
import { Kid } from '../Models/Kid'
import { ChangeChildBlogg } from '../views/admin/child/components/ChangeChildBlogg'
import HideChildParent from '../views/admin/child/components/HideChildParent'
import DeleteChild from '../views/admin/child/components/DeleteChild'
import AttendanceAndSchedule from '../views/admin/attendanceAndSchedule/AttendanceAndSchedule'
import { DocumentsEdit } from '../views/admin/documents/DocumentsEdit'
import { ModulesAddService } from '../views/admin/modulesAddServices/ModulesAddService'
import InviteHandleDashboard from '../views/admin/inviteHandleUsers/inviteHandleDashboard/InviteHandleDashboard'
import { InvitedUsers } from '../views/admin/inviteHandleUsers/invitedUsers/InvitedUsers'
import { WaitingApproval } from '../views/admin/inviteHandleUsers/waitingApproval/WaitingApproval'
import { ActiveUsers } from '../views/admin/inviteHandleUsers/activeUsers/ActiveUsers'
import { PausedUsers } from '../views/admin/inviteHandleUsers/pausedUsers/PausedUsers'
import { InvitePersonal } from '../views/admin/inviteHandleUsers/invitePersonal/invitePersonal'
import { InviteParents } from '../views/admin/inviteHandleUsers/inviteParents/inviteParents'
import { CreateSchoolAcc } from '../views/admin/createSchoolAcc/createSchoolAcc'
import { Settings } from '../views/settings/Settings'
import { DocAndLathund } from '../views/settings/DocAndLathund'
import { TermsAndPrivacy } from '../views/settings/TermsAndPrivacy'
import { VideoPlaybackPage } from '../components/VideoPlayerPage'

import { CreateEvent3 } from '../views/events3/CreateEvent3'

import { NoticeAndPush } from '../views/settings/NoticeAndPush'
import { DemoVideo } from '../views/settings/DemoVideo'
import { MyConnections } from '../views/settings/MyConnections'
import NoticeCenter from '../views/noticeCenter/NoticeCenter'
import { EventsAdmin } from '../views/admin/events/EventsAdmin'
import { EventsStartPage } from '../views/events3/EventsStartPage'
import i18n from 'i18next'
import { HelpScection } from '../views/events3/components/HelpSection'
import { NotificationContext } from './notification/notificationManger'

const AuthenticatedRoutes = observer(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { notify } = useContext(NotificationContext)
  const {
    fetchUserRole,
    selectFirstUserRole,
    savedUserRoleId,
    currentUserRole,
    userNotActive,
    readOnly,
    logoutUser,
  } = useContext(UserStoreContext)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const selFirstUserRole = async () => {
    await selectFirstUserRole().then((id) => {
      navigate('/dashboard/' + id)
    })
  }

  useEffect(() => {
    if (userNotActive) {
      Swal.fire({
        title: t('general.accountNotAvailible'),
        text: t('general.contactSchool'),
        icon: 'warning',
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonColor: '#7eb5f4',
        cancelButtonColor: '#d33',
        confirmButtonText: t('general.ok'),
      })
      selFirstUserRole()
    }
  }, [userNotActive])

  if (!i18n.isInitialized) {
    return null
  }
  const [state, setState] = useState<string>('Active')
  const [remaining, setRemaining] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const onIdle = async () => {
    setState('Idle')
    localStorage.removeItem('lastActive')
    await logoutUser().finally(() => {
      //window.location.reload()
      Swal.fire({
        title: t('login.sessionExpiredTitle'),
        text: t('login.sessionExpiredText'),
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#7eb5f4',
        cancelButtonColor: '#d33',
        confirmButtonText: t('login.sessionExpiredButton'),
      }).then((result) => {
        window.location.reload()
      })
    })
    await navigate('/')
  }
  const onActive = () => {
    setState('Active')
  }
  const onMessage = () => {
    setCount(count + 1)
  }
  const { getRemainingTime, getTabId, isLeader, isLastActiveTab, message } =
    useIdleTimer({
      onIdle,
      onActive,
      onMessage,
      timeout: 1800_000,
      crossTab: true,
      leaderElection: true,
      syncTimers: 200,
    })
  return (
    <Navigation>
      <Routes>
        {currentUserRole &&
        currentUserRole.role_type > USER_ROLE_TYPE.PARENT ? (
          <>
            <Route
              path={'/contactlists/:schoolId'}
              element={<ContactLists />}
            ></Route>
            <Route
              path={'/pdf-export/:skaType/:skaId'}
              element={<PdfBuilder />}
            ></Route>
            <Route
              path={'/pdf-export/' + PDFExportRoutes.UTBILDNING}
              element={<Utbildning />}
            ></Route>
            <Route
              path={'/pdf-export/' + PDFExportRoutes.BARNDOKUMENTATION}
              element={<Barndokumentation />}
            ></Route>
            <Route
              path={'/pdf-export/' + PDFExportRoutes.UNDERVISNING}
              element={<Undervisning />}
            ></Route>
            <Route
              path={'/settings/:schoolId/DocAndLatHund'}
              element={<DocAndLathund />}
            ></Route>
            <Route
              path={'/settings/:schoolId/NoticeAndPush'}
              element={<NoticeAndPush />}
            ></Route>
            <Route
              path={'/settings/:schoolId/DemoVideo'}
              element={<DemoVideo />}
            ></Route>
            <Route
              path={'/settings/:schoolId/TermsAndPrivacy'}
              element={<TermsAndPrivacy loginTerritory={0} />}
            ></Route>
            <Route
              path="/video-playback/:videoUrlBase64"
              element={<VideoPlaybackPage />}
            />
            <Route path="/noticeCenter/notice" element={<NoticeCenter />} />

            <Route path={'/pdf-export'} element={<SkaExport />}></Route>

            {/*
            Fix because in many cases, admins have staff_schedule_responsable as undefined - but admin should see everything.
          */}
            {currentUserRole &&
            currentUserRole.role_type >= USER_ROLE_TYPE.ADMIN ? (
              <>
                <Route path={'/admin/:schoolId'} element={<Admin />}></Route>
                <Route
                  path={'/admin/:schoolId/child/blogg'}
                  element={<ChangeChildBlogg />}
                ></Route>
                <Route
                  path={'/admin/:schoolId/child/parent'}
                  element={<HideChildParent />}
                ></Route>
                <Route
                  path={'/admin/:schoolId/child'}
                  element={<Child />}
                ></Route>

                <Route
                  path={'/admin/:schoolId/modules'}
                  element={<ModulesAddService />}
                ></Route>
                <Route
                  path={'/admin/:schoolId/attendance'}
                  element={<AttendanceAndSchedule />}
                ></Route>
                <Route
                  path={'/admin/:schoolId/documents'}
                  element={<DocumentsEdit />}
                ></Route>
                <Route
                  path={'/admin/:schoolId/child/pSU'}
                  element={<ChangeChildPSU kid={new Kid()} />}
                ></Route>
                <Route
                  path={'/admin/:schoolId/child/dep'}
                  element={<ChangeChildDep />}
                ></Route>
                <Route
                  path={'/admin/:schoolId/child/delete'}
                  element={<DeleteChild />}
                ></Route>
                <Route
                  path={'/admin/:schoolId/invite'}
                  element={<InviteHandleDashboard />}
                ></Route>
                <Route
                  path={'/admin/:schoolId/invitedUsers'}
                  element={<InvitedUsers />}
                ></Route>
                <Route
                  path={'/admin/:schoolId/waitingApproval'}
                  element={<WaitingApproval />}
                ></Route>
                <Route
                  path={'/admin/:schoolId/activeUsers'}
                  element={<ActiveUsers />}
                ></Route>
                <Route
                  path={'/admin/:schoolId/pausedUsers'}
                  element={<PausedUsers />}
                ></Route>
                <Route
                  path={'/admin/:schoolId/invitePersonal'}
                  element={<InvitePersonal />}
                ></Route>
                <Route
                  path={'/admin/:schoolId/inviteParents'}
                  element={<InviteParents />}
                ></Route>
                <Route
                  path={'/admin/:schoolId/createSchoolAcc'}
                  element={<CreateSchoolAcc />}
                ></Route>
                <Route
                  path={'/admin/:schoolId/eventsAdmin'}
                  element={<EventsAdmin />}
                ></Route>
              </>
            ) : null}

            {currentUserRole &&
            currentUserRole.role_type > USER_ROLE_TYPE.TEACHER ? (
              <>
                <Route
                  path={'/statistics/:schoolId/:kidId'}
                  element={<StatisticsDetail />}
                ></Route>
                <Route
                  path={'/statistics/:schoolId'}
                  element={<Statistics />}
                ></Route>
              </>
            ) : (
              <>
                {currentUserRole.role_type >= USER_ROLE_TYPE.TEACHER &&
                  !readOnly?.events &&
                  currentUserRole.staff_schedule_responsable && (
                    <>
                      <Route
                        path={'/statistics/:schoolId/:kidId'}
                        element={<StatisticsDetail />}
                      ></Route>
                      <Route
                        path={'/statistics/:schoolId'}
                        element={<Statistics />}
                      ></Route>
                    </>
                  )}
              </>
            )}
          </>
        ) : null}
        {currentUserRole?.school_pointer.event3Mod ? (
          <>
            {process.env.REACT_APP_PUBLIC_URL?.includes('localhost') ||
            process.env.REACT_APP_PUBLIC_URL?.includes('alfa') ? (
              <>
                <Route
                  path={'/events/create/:schoolId'}
                  element={<CreateEvent3 />}
                ></Route>
                <Route
                  path={'/events/:schoolId'}
                  element={<EventsStartPage />}
                ></Route>
              </>
            ) : (
              <Route
                path={'/events/:schoolId'}
                element={<CreateEvent3 />}
              ></Route>
            )}
          </>
        ) : (
          <>
            <Route path={'/events/:schoolId'} element={<Events />}></Route>
            <Route
              path={'/events/create/:schoolId'}
              element={<EventsCreate />}
            ></Route>
            <Route
              path={'/events/edit/:schoolId/:eventId'}
              element={<EventsEdit />}
            ></Route>
          </>
        )}
        {/*  <Route
          path={'/eventDetail/:eventTimeId'}
          element={<EventDetailPage />}
        ></Route> */}
        <Route path={'/portfolio-kid'} element={<Portfolio />}></Route>
        <Route path={'/portfolio/:kidId'} element={<Portfolio />}></Route>
        <Route path={'/blog/:schoolId'} element={<Blog />}></Route>
        <Route
          path={'/settings/:schoolId/MyConnections'}
          element={<MyConnections />}
        ></Route>
        <Route
          path={'/dashboard/:userRoleId'}
          element={<DashboardView />}
        ></Route>
        <Route path={'/documents/:schoolId'} element={<DocumentMain />}></Route>
        <Route
          path={'/documents-type/:schoolId'}
          element={<DocumentsFromType />}
        />
        <Route path={'/documents-id/:folderId'} element={<Documents />} />

        <Route path={'/present'} element={<Present />}></Route>
        <Route path={'/kids'} element={<OurKids />}></Route>

        <Route path={'/settings'} element={<Settings />}></Route>

        <Route path={'/absence/*'} element={<Absence />}></Route>
        <Route path={'/documentation'} element={<Documentation />}></Route>
        <Route
          path={'/documentation/:id'}
          element={<DocumentationViewer />}
        ></Route>
        <Route path={'/contact/:userRoleId'} element={<Contact />}></Route>
        <Route path={'/'} element={<SelectDashboard />}></Route>
        <Route path={'/notes/:kidId'} element={<Notes />}></Route>
        {/* <Route path={'/helpSection'} element={<HelpScection />}></Route> */}
      </Routes>
    </Navigation>
  )
})

function UnauthenticatedRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/*" element={<Navigate replace to="/" />} />p
    </Routes>
  )
}

export const AppRoutes: React.FC = observer(() => {
  const { currentUser } = useContext(UserStoreContext)
  return (
    <>{currentUser ? <AuthenticatedRoutes /> : <UnauthenticatedRoutes />}</>
  )
})
