import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { t } from 'i18next'
import CustomRadioButton from '../../../components/CustomRadioButton'
import { Button } from '../../../components/Button'
import {
  CreateEventParams,
  EVENT3_POST_TO,
  EVENT3_TYPE,
} from '../../../store/event3Store'
import { usePostToSelections } from '../CreateEvent3'
import { Department } from '../../../Models/Department'
import { School } from '../../../Models/School'
import { useTranslation } from 'react-i18next'
import ModalRegular from '../../../components/ModalRegular'
import { useParams } from 'react-router-dom'
import { DepartmentStoreContext } from '../../../store/departmentStore'
import { SpecificPersonModal } from './SpecificPersonModal'

interface Props {
  newEvent: CreateEventParams
  departments: Department[]
  schools?: School[]
  onChange: (e: CreateEventParams) => void
  onDone: () => void // hämta värdena och visa från riktig db
  onSave: () => void // spara värdena
}

interface Props2 {
  newEvent: CreateEventParams
  onChange: (e: CreateEventParams) => void
  departments: Department[]
  schools?: School[]
  allowMultiSchools?: boolean
  setPostToMultiSchoolsMode?: (value: boolean) => void
  postToMultiSchoolsMode?: boolean
}

export const PostEventToSelections: React.FC<Props2> = observer(
  (props: Props2) => {
    const postToSelections = usePostToSelections()
    const { t } = useTranslation()
    const { departmentsForMultiSchool } = useContext(DepartmentStoreContext)
    let chosenDepIds = props.newEvent.depIds || []
    let chosenSchoolsIds = props.newEvent.multiSchools || []

    const [showMultiSchoolsModal, setShowMultiSchoolsModal] =
      useState<boolean>(false)
    const [showDepartmentsModal, setShowDepartmentsModal] =
      useState<boolean>(false)
    const [showSpecificPersonModal, setShowSpecificPersonModal] =
      useState<boolean>(false)

    useEffect(() => {
      if (props.postToMultiSchoolsMode) {
        // Set all department IDs when multi-schools mode is active and departments are updated
        props.onChange({
          ...props.newEvent,
          depIds: props.departments.map((dep) => dep.id),
        })
      }
    }, [props.departments, props.postToMultiSchoolsMode])

    const toggleMultiSchoolsMode = async () => {
      props.setPostToMultiSchoolsMode?.(!props.postToMultiSchoolsMode)
      if (props.postToMultiSchoolsMode) {
        props.onChange({
          ...props.newEvent,
          multiSchools: [`${schoolId}`],
          depIds: [],
        })
        chosenDepIds = []
      } else {
        props.onChange({
          ...props.newEvent,
          multiSchools: props.schools?.map((school) => school.id),
        })
      }
    }
    const alternativesBgColor =
      props.newEvent.eventType === 'info'
        ? 'bg-[#7EB5F4]'
        : props.newEvent.eventType === 'important'
        ? 'bg-[#F26D6D]'
        : props.newEvent.eventType === 'meeting'
        ? 'bg-[#ffa948]'
        : props.newEvent.eventType === 'booking'
        ? 'bg-[#C99CF1]'
        : props.newEvent.eventType === 'activities'
        ? 'bg-[#6BBD72]'
        : 'bg-[#7EB5F4]'

    const btnTextColor = 'ffffff'
    const { schoolId } = useParams()

    return (
      <>
        <h2 className="text-xl font-bold text-left">
          {t('events.postToEvent')}
        </h2>
        <div className="flex flex-col gap-y-2 mt-1 animate-fadeIn ">
          <CustomRadioButton
            options={postToSelections}
            disable={props.newEvent.eventType === EVENT3_TYPE.BOOKING}
            onChange={(value) => {
              if (value === EVENT3_POST_TO.ALL) {
                props.onChange({
                  ...props.newEvent,
                  postTo: value,
                  onlyStaff: undefined,
                  destKids: undefined,
                  destRoles: undefined,
                })
              } else if (value === EVENT3_POST_TO.ONLY_STAFF) {
                props.onChange({
                  ...props.newEvent,
                  postTo:
                    props.newEvent.postTo === value
                      ? EVENT3_POST_TO.ALL
                      : value,
                  onlyStaff: props.newEvent.onlyStaff ? false : true,
                  destKids: undefined,
                  destRoles: undefined,
                })
              } else if (value === EVENT3_POST_TO.SPECIFIC_USERS) {
                props.onChange({
                  ...props.newEvent,
                  postTo: value,
                  onlyStaff: undefined,
                  destKids: [],
                  destRoles: [],
                })
              }
            }}
            value={props.newEvent.postTo!!}
          />
          {props.allowMultiSchools && (
            <div className="rounded-2xl shadow-sm p-2 bg-white">
              <CustomRadioButton
                options={[
                  {
                    value: EVENT3_POST_TO.MULTI_SCHOOLS,
                    label: `${t('events.postMultipleSchools')} (${
                      chosenSchoolsIds.length
                    }/${props.schools?.length})`,
                    text: '',
                  },
                ]}
                onChange={() => {
                  toggleMultiSchoolsMode()
                }}
                value={props.postToMultiSchoolsMode ? 'true' : 'false'}
              />
              {props.postToMultiSchoolsMode && (
                <>
                  {chosenSchoolsIds.length > 0 && (
                    <div className="flex whitespace-nowrap pl-2 gap-2 mb-3 py-1 h-[40px] overflow-auto scrollbar-hide animate-fadeIn">
                      {props.schools?.map((school) => {
                        if (chosenSchoolsIds.includes(school.id))
                          return (
                            <div
                              className={`text-white ${
                                props.newEvent.eventType === 'info'
                                  ? 'bg-eventsMain'
                                  : 'bg-absenceMain'
                              } rounded-full flex items-center justify-center px-3 text-sm font-semibold`}
                              key={school.id}
                            >
                              {school.display_school_name !== undefined
                                ? school.display_school_name
                                : school.schoolName}
                            </div>
                          )
                      })}
                    </div>
                  )}
                  {/* {chosenSchoolsIds.length === 0 && (
                  <p className="text-black p-2 animate-fadeIn">
                    !!Inga valda skolor{' '}
                  </p>
                )} */}
                  <p
                    className="flex w-full justify-end text-eventsMain cursor-pointer text-sm font-semibold pr-2 animate-fadeIn"
                    onClick={() => setShowMultiSchoolsModal(true)}
                  >
                    {t('events.chooseOrEdit')}
                  </p>
                </>
              )}
            </div>
          )}
          <div className="animate-fadeIn">
            {props.newEvent.multiSchools &&
            props.newEvent.multiSchools?.length > 1 ? (
              <div className="rounded-2xl shadow-sm py-4 pl-4 bg-white">
                <h2 className="font-semibold text-left mb-2">
                  {t('events.selectedDepartments')}
                  {' ('}
                  {chosenDepIds?.length}
                  {'/'}
                  {props.departments.length}
                  {')'}
                </h2>
                {chosenDepIds.length > 0 && (
                  <div className="flex whitespace-nowrap gap-2 mb-1 py-1 h-[40px] overflow-auto scrollbar-hide animate-fadeIn">
                    {props.departments?.map((dep) => {
                      if (chosenDepIds.includes(dep.id))
                        return (
                          <div
                            className={`text-white ${
                              props.newEvent.eventType === 'info'
                                ? 'bg-eventsMain'
                                : 'bg-absenceMain'
                            } rounded-full flex items-center justify-center px-3 text-sm font-semibold`}
                            key={dep.id}
                          >
                            {/* <Button
                            key={dep.id}
                            size="sm"
                            variant={'lightPurple'}
                            label={dep.klassName}
                          /> */}
                            {dep.klassName}
                          </div>
                        )
                    })}
                  </div>
                )}
                <p
                  className="flex w-full justify-end text-eventsMain cursor-pointer text-sm font-semibold pr-2 animate-fadeIn"
                  onClick={() => setShowDepartmentsModal(true)}
                >
                  {t('events.chooseOrEdit')}
                </p>
              </div>
            ) : (
              <div className=" rounded-2xl shadow-sm bg-white py-4 pl-4">
                <h2 className="font-semibold text-left">
                  {t('events.selectedDepartments')}
                  {' ('}
                  {chosenDepIds?.length}
                  {'/'}
                  {props.departments.length}
                  {')'}
                </h2>

                <div className="flex whitespace-nowrap  gap-2 mb-3 py-1 h-[50px] overflow-auto scrollbar-hide">
                  {props.departments.map((dep) => {
                    return (
                      <Button
                        key={dep.id}
                        textColor={btnTextColor}
                        size="sm"
                        variant={
                          props.newEvent.depIds?.find(
                            (depId) => depId === dep.id,
                          )
                            ? 'custom'
                            : 'fullWhiteGrayText'
                        }
                        bgrondColor={alternativesBgColor}
                        label={dep.klassName}
                        onClick={() => {
                          if (chosenDepIds?.find((depId) => depId === dep.id)) {
                            // Remove from array
                            chosenDepIds = chosenDepIds.filter(
                              (depId) => depId !== dep.id,
                            )
                          } else {
                            // Add to array
                            chosenDepIds?.push(dep.id)
                          }
                          props.onChange({
                            ...props.newEvent,
                            depIds: chosenDepIds,
                          })
                        }}
                      />
                    )
                  })}
                </div>
                <div className="flex flex-row items-center justify-end w-full pr-4">
                  <div>
                    {props.departments.length ===
                    props.newEvent.depIds?.length ? (
                      <h4
                        className="text-eventsMain select-none cursor-pointer font-semibold rounded-full text-sm"
                        onClick={() => {
                          props.onChange({
                            ...props.newEvent,
                            depIds: [],
                          })
                        }}
                      >
                        <>{t('multiselect.deSelectAll')}</>
                      </h4>
                    ) : (
                      <h4
                        className="text-eventsMain select-none cursor-pointer font-semibold rounded-full  text-sm"
                        onClick={() => {
                          props.onChange({
                            ...props.newEvent,
                            depIds: props.departments.map((dep) => dep.id),
                          })
                        }}
                      >
                        <>{t('settings.markAll')}</>
                      </h4>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="rounded-2xl shadow-sm bg-white font-semibold px-4 py-2 flex flex-col items-center">
            <p className="w-full flex justify-start">
              {t('events.shareToSpecificUser')}
            </p>
            <p
              onClick={() => {
                setShowSpecificPersonModal(true)
              }}
              className="flex w-full justify-end text-eventsMain cursor-pointer text-sm"
            >
              {t('events.chooseOrEdit')}
            </p>
          </div>
        </div>
        {showSpecificPersonModal && (
          <SpecificPersonModal
            onSaved={() => {}}
            newEvent={props.newEvent}
            onClose={() => {
              setShowSpecificPersonModal(false)
            }}
          />
        )}
        <ModalRegular
          title={t('events.selectSchoolsToPostTo')}
          isOpen={showMultiSchoolsModal}
          closeColor="white"
          className={'!max-w-xl p-4'}
          classNameTitle="mb-4 w-full text-black !important flex justify-center font-semibold"
          onClose={() => {
            setShowMultiSchoolsModal(false)
          }}
        >
          <p
            className="flex justify-end w-full cursor-pointer text-eventsMain font-semibold"
            onClick={() => {
              if (
                props.newEvent.multiSchools?.length !== props.schools?.length
              ) {
                props.onChange({
                  ...props.newEvent,
                  multiSchools: props.schools?.map((school) => school.id),
                })
              } else {
                props.onChange({
                  ...props.newEvent,
                  multiSchools: [],
                })
              }
            }}
          >
            {props.newEvent.multiSchools?.length !== props.schools?.length
              ? t('multiselect.selectAll')
              : t('multiselect.deSelectAll')}
          </p>
          <div className="flex flex-wrap whitespace-nowrap gap-2 mb-3 py-1 h-full overflow-auto scrollbar-hide pl-2">
            {props.schools?.map((school) => {
              return (
                <Button
                  key={school.id}
                  size="sm"
                  variant={
                    props.newEvent.multiSchools?.find(
                      (schoolId) => schoolId === school.id,
                    )
                      ? 'custom'
                      : 'fullWhiteGrayText'
                  }
                  label={
                    school.display_school_name !== undefined
                      ? school.display_school_name
                      : school.schoolName
                  }
                  bgrondColor={alternativesBgColor}
                  textColor="ffffff"
                  onClick={() => {
                    if (
                      chosenSchoolsIds?.find(
                        (schoolId) => schoolId === school.id,
                      )
                    ) {
                      // Remove from array
                      chosenSchoolsIds = chosenSchoolsIds?.filter(
                        (schoolId) => schoolId !== school.id,
                      )
                    } else {
                      // Add to array
                      chosenSchoolsIds?.push(school.id)
                    }
                    props.onChange({
                      ...props.newEvent,
                      multiSchools: chosenSchoolsIds,
                    })
                  }}
                />
              )
            })}
          </div>
          <div className="flex justify-center w-full mb-4 bg-gradient-to-t from-white absolute bottom-0 left-0">
            <Button
              size="sm"
              className="w-[85%]"
              variant="primary"
              label="Done"
              fontSize="font-bold"
              onClick={() => setShowMultiSchoolsModal(false)}
            />
          </div>
        </ModalRegular>
        {/** popup for choosing a department when multi schools */}
        <ModalRegular
          title={t('events.selectDepartmentsToPostTo')}
          isOpen={showDepartmentsModal}
          /* bgColor="bg-eventsMain p-4" */
          closeColor="white"
          className={'!max-w-xl px-4 pt-4'}
          classNameTitle="mb-4 w-full text-black !important flex justify-center font-semibold"
          onClose={() => {
            setShowDepartmentsModal(false)
          }}
        >
          {/** this will be inside a popup to select departments from */}
          <div className="h-[400px] overflow-auto py-3 mb-3">
            <h4
              className="text-eventsMain select-none cursor-pointer font-semibold rounded-full text-sm flex w-full justify-end"
              onClick={() => {
                if (
                  props.newEvent.depIds?.length !== props.departments?.length
                ) {
                  props.onChange({
                    ...props.newEvent,
                    depIds: props.departments.map((dep) => dep.id),
                  })
                } else {
                  props.onChange({
                    ...props.newEvent,
                    depIds: [],
                  })
                }
              }}
            >
              {props.newEvent.depIds?.length !== props.departments?.length
                ? t('multiselect.selectAll')
                : t('multiselect.deSelectAll')}
            </h4>
            {props.newEvent.multiSchools?.map((multiSchoolId) => {
              return (
                <div className="flex flex-col gap-y-1 last:mb-4">
                  <p className="flex items-center justify-between w-full pl-2 font-semibold">
                    {
                      props.schools?.find(
                        (school) => school.id === multiSchoolId,
                      )?.display_school_name
                    }
                  </p>
                  {/* <div className="flex  gap-2 mb-3 py-1 h-[50px] overflow-auto scrollbar-hide pl-2"></div> */}
                  <div className="flex flex-wrap whitespace-nowrap  gap-2 mb-3 py-1 overflow-auto scrollbar-hide pl-2">
                    {props.departments
                      .filter((dep) => dep.school_pointer.id === multiSchoolId)
                      .map((dep) => {
                        return (
                          <Button
                            size="sm"
                            variant={
                              props.newEvent.depIds?.find(
                                (depId) => depId === dep.id,
                              )
                                ? 'custom'
                                : 'fullWhiteGrayText'
                            }
                            label={dep.klassName}
                            bgrondColor={alternativesBgColor}
                            onClick={() => {
                              if (
                                chosenDepIds?.find((depId) => depId === dep.id)
                              ) {
                                // Remove from array
                                chosenDepIds = chosenDepIds.filter(
                                  (depId) => depId !== dep.id,
                                )
                              } else {
                                // Add to array
                                chosenDepIds?.push(dep.id)
                              }
                              props.onChange({
                                ...props.newEvent,
                                depIds: chosenDepIds,
                              })
                            }}
                          />
                        )
                      })}
                  </div>
                </div>
              )
            })}
          </div>
          <div className="flex justify-center items-center w-full  h-[60px] bg-gradient-to-t from-white absolute bottom-0 left-0">
            <Button
              size="sm"
              className="w-[85%]"
              variant="primary"
              label="Done"
              onClick={() => setShowDepartmentsModal(false)}
            />
          </div>
        </ModalRegular>
      </>
    )
  },
)

export const PostEventTo: React.FC<Props> = observer((props: Props) => {
  const [openSelections, setOpenSelections] = useState(false)

  return (
    <>
      <div onClick={() => setOpenSelections(!openSelections)}>
        <div className="flex flex-col gap-y-2">
          <div className="font-bold">
            <>{t('events.postToEvent')}:</>
          </div>

          {props.newEvent.multiSchools ? (
            <>
              {props.newEvent.multiSchools.map((multiSchoolId) => (
                <div key={multiSchoolId} className="flex flex-col gap-y-1">
                  <div className="flex-1 w-full mt-2">
                    <b>
                      {
                        props.schools?.find(
                          (school) => school.id === multiSchoolId,
                        )?.display_school_name
                      }
                    </b>
                  </div>
                  <div className="flex flex-1 w-full gap-2">
                    {props.departments
                      .filter((dep) => dep.school_pointer.id === multiSchoolId)
                      .map(
                        (dep) =>
                          props.newEvent.depIds?.includes(dep.id) && (
                            <div
                              key={dep.id}
                              className="text-sm bg-blue-100 rounded-full px-2"
                            >
                              {dep.klassName}
                            </div>
                          ),
                      )}
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="flex flex-row flex-wrap w-full gap-2">
              {props.departments.map(
                (dep) =>
                  props.newEvent.depIds?.includes(dep.id) && (
                    <>
                      <Button
                        size="xs"
                        variant={
                          props.newEvent.depIds?.find(
                            (depId) => depId === dep.id,
                          )
                            ? 'lightPurple'
                            : 'fullWhiteGrayText'
                        }
                        label={dep.klassName}
                        onClick={() => {}}
                      />
                    </>
                  ),
              )}
            </div>
          )}
        </div>
      </div>
    </>
  )
})
