import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import { BloggDoc } from '../Models/BloggDoc'

interface Props {
  children: React.ReactNode
  isOpen: boolean
  onClose: any
  title?: string
  className?: string
  classNameTitle?: string
  overlayClassName?: string
  bgColor?: string
  closeColor?: string
  bloggDoc?: BloggDoc
  hideCloseButton?: boolean
  dataAction?: string
}

export default function ModalRegular({
  bgColor,
  children,
  isOpen,
  onClose,
  title,
  className,
  classNameTitle,
  overlayClassName,
  closeColor,
  bloggDoc,
  hideCloseButton,
  dataAction,
}: Props) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        style={{ zIndex: 11000 }}
        as="div"
        className="fixed inset-0 overflow-y-auto bg-black bg-opacity-50 backdrop-blur-sm z-50 h-full" /* className="fixed inset-0 flex items-center  justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50"  */
        onClose={() => onClose()}
      >
        <Dialog.Overlay
          className={classNames(
            'fixed inset-0 bg-black opacity-50 h-screen overflow-y-auto',
            overlayClassName,
          )}
        />

        <div className="min-h-screen px-3 text-center">
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={classNames(
                'relative rounded-3xl inline-block w-full',
                'max-w-2xl my-8 overflow-hidden text-left align-middle',
                ' transition-all transform bg-white shadow-xl min-h-[300px] max-h-[600px] overflow-y-auto',
                className,
              )}
            >
              <div
                className={classNames(
                  'flex rounded-t-2xl   ',

                  bgColor,
                  (title === '' || title === ' ') && 'h-[50px]',
                )}
              >
                <Dialog.Title
                  as="p"
                  className={classNames(
                    `text-xl font-medium leading-6 ${
                      !classNameTitle?.includes('text-black') && 'text-white'
                    }`,
                    classNameTitle,
                  )}
                >
                  {title}
                </Dialog.Title>
                {!hideCloseButton && (
                  <button
                    type="button"
                    className="-m-4 p-2 text-gray-500 hover:text-gray-400 absolute right-6 top-6 outline-none"
                    onClick={(e) => {
                      e.stopPropagation()
                      onClose()
                    }}
                  >
                    <span className="sr-only">Close panel</span>
                    <FontAwesomeIcon
                      className="h-6 w-6"
                      icon={faClose}
                      color={closeColor}
                    />
                  </button>
                )}
              </div>

              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}
