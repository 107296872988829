import {
  faArrowsRepeat,
  faCheck,
  faLeft,
  faLeftFromLine,
  faRight,
  faRightFromLine,
  faTrashCan,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment, { Moment } from 'moment'
import { useEffect, useState } from 'react'
import { getDateForTranslatation } from '../views/events/Events'
import { CalendarCell } from './CalendarCell'
import { CalendarDateIcon } from './CalendarDateIcon'
import {
  CreateEventParams,
  EVENT3_TYPE,
  IsoDate,
  IsoDateTime,
  IsoTime,
  RecurrenceParams,
  Times,
} from '../store/event3Store'
import classNames from 'classnames'
import { Button } from './Button'
import ModalRegular from './ModalRegular'
import i18n from '../i18nextConfig'
import DatePicker from 'react-datepicker'
import {
  faCalendarAlt,
  faCaretDown,
  faClock,
  faMinus,
  faPen,
  faPenToSquare,
} from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'
import Singleselect from './SingleSelectOpen'
import { useTranslation } from 'react-i18next'
import {
  createManyEventTimesForBooking,
  useCountToLengthsToMeeting,
  useDaysSelections,
  useLengthsToMeeting,
  useRepeatSelections,
} from '../views/events3/CreateEvent3'
import { TabHeaderSlider } from '../views/documents/DocumentMain'
import { motion } from 'framer-motion'
import { EventDots } from '../views/events3/components/EventDots'

interface Props {
  recurringDaysWave?: boolean
  lastMonthDate?: Moment
  selected?: Moment
  onChangeEvent: (e: CreateEventParams) => void
  newEvent?: CreateEventParams
  onDateClick: (
    value: string[],
    lastDate: string,
    recurrenceEndDate?: boolean,
  ) => void
  changeLastMonthDate?: (value: Moment) => void
  changeMonth?: (value: Moment) => void
  onAddTime?: (times: { start: string; end: string }, dates: string[]) => void
  onRemoveTime?: (id: string) => void
  onEditTime?: (id: string, times: { start: string; end: string }) => void
  onEditTimes?: (
    times: {
      date: IsoDate
      start?: IsoDateTime
      end?: IsoDateTime
      fakeId: string
    }[],
  ) => void
  dates: string[] | undefined
  multiDates?: boolean
  isRecurrence?: boolean
  recurrence?: RecurrenceParams
  onEditRecurrence?: (value: boolean) => void
  allowTimes?: boolean
  times?: {
    date: IsoDate
    start?: IsoDateTime
    end?: IsoDateTime
    fakeId: string
  }[]
  currentMonth?: Moment
}

export interface MultipleTimes {
  start?: IsoTime
  length?: string
  pause?: string
  amount?: string
}

const initialMultipleTimesValues: MultipleTimes = {
  start: '12:00',
  length: '15',
  pause: '5',
  amount: '5',
}

export const Calendar: React.FC<Props> = ({
  recurringDaysWave,
  lastMonthDate = moment().subtract(1, 'months'),
  onChangeEvent,
  newEvent,
  onDateClick,
  changeLastMonthDate,
  changeMonth,
  onAddTime,
  onEditTimes,
  onEditRecurrence,
  dates,
  multiDates,
  isRecurrence,
  recurrence,
  allowTimes,
  times,
  currentMonth = moment(),
}) => {
  const [currentDate, setCurrentDate] = useState(currentMonth)
  /*  const [firstDate, setFirstDate] = useState(currentMonth.startOf('month'))
  const [lastDate, setLastDate] = useState(currentMonth.endOf('month'))
  const [daysOfMonth, setDaysOfMonth] = useState(currentMonth.daysInMonth()) */
  const [multipleTimes, setMultipleTimes] = useState<MultipleTimes>(
    initialMultipleTimesValues,
  )
  const [recurrenceDateSelect, setRecurrenceDateSelect] = useState<
    'startDate' | 'endDate'
  >('startDate')
  const [selectedDates, setSelectedDates] = useState<string[]>([])
  const [showNewTimeModal, setShowNewTimeModal] = useState(false)
  const [showEditDateModal, setShowEditDateModal] = useState(false)
  const [showMultipleTimes, setShowMultipleTimes] = useState(false)
  const [multipleTimesStep, setMultipleTimesStep] = useState(1)
  const [editInterval, setEditInterval] = useState(true)
  const [editEndDate, setEditEndDate] = useState(false)
  const [newTime, setNewTime] = useState({
    start: moment().format('HH:00'),
    end: moment().add(1, 'hours').format('HH:00'),
  })
  const [editTime, setEditTime] = useState<Times | null>(null)
  const [editTimes, setEditTimes] = useState<
    {
      date: IsoDate
      start?: IsoDateTime
      end?: IsoDateTime
      fakeId: string
    }[]
  >([])
  const { t } = useTranslation()
  const [showNewTimeModalRepeat, setShowNewTimeModalRepeat] = useState(false)
  const [groupedTimes, setGroupedTimes] = useState<string[]>()
  const [slideOutDate, setSlideOutDate] = useState<boolean>(false)
  const [responsiveHeight, setResponsiveHeight] = useState<string>('96')

  const repeatSelections = useRepeatSelections()

  const days = useDaysSelections()
  const lengthsToMeeting = useLengthsToMeeting()
  const countToMeeting = useCountToLengthsToMeeting()

  useEffect(() => {
    if (times) {
      /*  const pushedDates: string[] = [] */
      const datesInTimes: string[] = []

      for (const time of times) {
        if (!datesInTimes.find((date) => date === time.date)) {
          datesInTimes.push(time.date)
        }
      }
      if (datesInTimes) {
        console.log(datesInTimes)

        setGroupedTimes(
          datesInTimes.sort((a, b) => {
            return new Date(a).getTime() - new Date(b).getTime()
          }),
        )
        setSelectedDates(
          selectedDates.filter((sD) => datesInTimes.includes(sD)),
        )
      }
    }
  }, [times])

  useEffect(() => {
    if (times) {
      setEditTimes(times)
    }
  }, [times])

  const prefixDays =
    moment(currentDate.startOf('month')).day() === 0
      ? 6
      : moment(currentDate.startOf('month')).day() - 1
  const suffixDays =
    moment(currentDate.endOf('month')).day() === 0
      ? 0
      : 7 - moment(currentDate.endOf('month')).day()
  //const [prefixDays, setPrefixDays] = useState(firstDate.day())
  //const [suffixDays, setSuffixDays] = useState(6 - lastDate.day())
  const daysOfWeek = [
    t('days.monday'),
    t('days.tuesday'),
    t('days.wednesday'),
    t('days.thursday'),
    t('days.friday'),
    t('days.saturday'),
    t('days.sunday'),
  ]

  const dateInCell = (cellDate: string) => {
    return dates?.find((ab: any) => cellDate === ab)
  }
  const showDays = () => {
    const div = []

    for (var i = 0; i < currentDate.daysInMonth(); i++) {
      //let red = false
      const cellDate = moment(
        moment(currentDate).format('YYYY') +
          '-' +
          moment(currentDate).format('MM') +
          '-' +
          (i + 1).toString(),
      ).format('YYYY-MM-DD')

      const active = dateInCell(cellDate)

      div.push(
        <CalendarCell
          onClick={() => {
            if (
              moment(cellDate).isoWeekday() === 6 ||
              moment(cellDate).isoWeekday() === 7
            ) {
            } else {
              if (isRecurrence) {
                if (recurrenceDateSelect === 'startDate') {
                  if (moment(cellDate) < moment(recurrence?.endsOn)) {
                    handleDateClick(cellDate)
                  }
                } else {
                  if (moment(cellDate) > moment(recurrence?.startsOn)) {
                    handleDateClick(cellDate)
                  }
                }
              } else {
                handleDateClick(cellDate)
              }
            }
          }}
          active={
            active
              ? true
              : newEvent && !newEvent.times?.length && !isRecurrence
              ? cellDate === moment().format('YYYY-MM-DD')
                ? true
                : false
              : false
          }
          key={i}
          opacity={
            isRecurrence
              ? recurrenceDateSelect === 'startDate'
                ? moment(cellDate) > moment(recurrence?.endsOn)
                : moment(cellDate) < moment(recurrence?.startsOn)
              : moment(cellDate).isoWeekday() === 6 ||
                moment(cellDate).isoWeekday() === 7
              ? true
              : false
          }
          className="§"
        >
          <>{i + 1}</>
          <EventDots date={cellDate} type="events3" />
        </CalendarCell>,
      )
    }

    return div
  }
  /*const amountOfAbsenceDaysCurrentMonth = () => {
    let count = 0

    for (var i = 0; i < daysOfMonth; i++) {
      //let red = false
      const cellDate = moment(
        moment(value).format('YYYY') +
          '-' +
          moment(value).format('MM') +
          '-' +
          (i + 1).toString(),
      ).format('YYYY-MM-DD')

      const active = dateInCell(cellDate)
      if (active) {
        count = count + 1
      }
    }
    return count
  }*/

  const handleDateClick = (date: string) => {
    if (multiDates) {
      if (dates?.includes(date)) {
        const oldDates: string[] = dates ? dates : []
        setSlideOutDate(true)
        onDateClick(
          oldDates.filter((d) => d !== date),
          date,
          isRecurrence
            ? recurrenceDateSelect === 'endDate'
              ? true
              : false
            : undefined,
        )
        setSelectedDates(selectedDates.filter((d) => d !== date))
        setSlideOutDate(false)
      } else {
        const oldDates: string[] = dates ? dates : []
        /*const newDates: string[] =*/ oldDates.push(date) //dates ? dates.push(date) as string[] : [date]
        onDateClick(
          oldDates,
          date,
          isRecurrence
            ? recurrenceDateSelect === 'endDate'
              ? true
              : false
            : undefined,
        )
        setSelectedDates([...selectedDates, date])
      }
    } else {
      if (dates?.includes(date)) {
        onDateClick([], date, undefined)
      } else {
        onDateClick([date], date, undefined)
      }
    }
  }

  const showPrefixDays = () => {
    const div = []
    for (var i = 0; i < currentDate.daysInMonth(); i++) {
      if (i > currentDate.daysInMonth() - (prefixDays + 1)) {
        div.push(
          <CalendarCell className="" opacity key={i}>
            {i + 1}
          </CalendarCell>,
        )
      }
    }
    return div
  }
  const showSuffixDays = () => {
    const div = []
    for (var i = 0; i < suffixDays; i++) {
      div.push(
        <CalendarCell className="" opacity key={i}>
          {i + 1}
        </CalendarCell>,
      )
    }
    return div
  }

  const nextMonth = () => {
    //onChange(moment(currentDate).add(1, 'months'))
    setCurrentDate(moment(currentDate).add(1, 'months'))
    if (changeLastMonthDate) {
      changeLastMonthDate(moment(lastMonthDate).add(1, 'months'))
    }

    if (changeMonth) {
      changeMonth(moment(currentMonth).add(1, 'months'))
    }
  }

  const prevMonth = () => {
    //onChange(moment(currentDate).subtract(1, 'months'))
    setCurrentDate(moment(currentDate).subtract(1, 'months'))
    if (changeLastMonthDate) {
      changeLastMonthDate(moment(lastMonthDate).subtract(1, 'months'))
    }
    if (changeMonth) {
      changeMonth(moment(currentMonth).subtract(1, 'months'))
    }
  }

  const nextYear = () => {
    //onChange(moment(currentDate).add(1, 'years'))
    setCurrentDate(moment(currentDate).add(1, 'years'))
    if (changeLastMonthDate) {
      changeLastMonthDate(moment(lastMonthDate).add(1, 'years'))
    }
    if (changeMonth) {
      changeMonth(moment(currentMonth).add(1, 'years'))
    }
  }

  const prevYear = () => {
    //onChange(moment(currentDate).subtract(1, 'years'))
    setCurrentDate(moment(currentDate).subtract(1, 'years'))
    if (changeLastMonthDate) {
      changeLastMonthDate(moment(lastMonthDate).subtract(1, 'years'))
    }
    if (changeMonth) {
      changeMonth(moment(currentMonth).subtract(1, 'years'))
    }
  }

  return (
    <>
      <div className="flex flex-row justify-start text-xl text-gray-800 font-medium ml-5">
        <>{/*t('absence.absenceThisMonth')*/}</>
        <div className="text-absenceMain font-bold ml-2">
          {/*{amountOfAbsenceDaysCurrentMonth()} <>{t('absence.absenceDays')}</>*/}
        </div>
      </div>

      <div className="flex flex-wrap mb-2 bg-puple-400">
        <div className="h-auto flex-1 mt-12 sm:m-3 font-medium flex flex-col sm:flex-row">
          {/*<div className="w-[400px] grid grid-cols-7 overflow-visible ">*/}
          <div
            className={multiDates ? 'basis-full sm:basis-7/12' : 'basis-full'}
          >
            <div className="grid grid-cols-7 gap-2 overflow-visible shadow-md bg-white/100 rounded-2xl p-2">
              <CalendarCell
                onClick={prevYear}
                className=" text-blueDark  cursor-pointer font-bold hover:scale-125 transition ease-in rounded-full "
              >
                <FontAwesomeIcon icon={faLeftFromLine} />
              </CalendarCell>
              <CalendarCell
                onClick={prevMonth}
                className=" text-blueDark  cursor-pointer font-bold hover:scale-125 transition ease-in rounded-full "
              >
                <FontAwesomeIcon icon={faLeft} />
              </CalendarCell>
              <CalendarCell className="col-span-3 font-bold text-xl cursor-default capitalize text-gray-700">
                {getDateForTranslatation(currentDate.format('MM'))}{' '}
                {currentDate.format('YYYY')}
              </CalendarCell>
              <CalendarCell
                onClick={nextMonth}
                className=" text-blueDark  cursor-pointer font-bold hover:scale-125 transition ease-in rounded-full  "
              >
                <FontAwesomeIcon icon={faRight} />
              </CalendarCell>

              <CalendarCell
                onClick={nextYear}
                className=" text-blueDark  cursor-pointer font-bold hover:scale-125 transition ease-in rounded-full"
              >
                <FontAwesomeIcon icon={faRightFromLine} />
              </CalendarCell>

              {daysOfWeek.map((day) => (
                <CalendarCell
                  className=" text-gray-700 font-semibold cursor-default"
                  key={day}
                >
                  {day}
                </CalendarCell>
              ))}
              {showPrefixDays()}
              {showDays()}
              {showSuffixDays()}
            </div>
          </div>
          {multiDates && (
            <div className="basis-full sm:basis-5/12 bg-resd-500 ml-4">
              <div
                className={`flex flex-col gap-1 px-1  h-${
                  isRecurrence ? 'full' : responsiveHeight
                } flex-nowrap bg-white/90 shadow-md rounded-2xl`}
              >
                {newEvent?.eventType !== EVENT3_TYPE.BOOKING && (
                  <TabHeaderSlider
                    tabs={[
                      { label: t('blog.chooseDate'), value: 'date' },
                      { label: t('general.repeat'), value: 'repeat' },
                    ]}
                    activeTab={
                      isRecurrence
                        ? { label: t('general.repeat'), value: 'repeat' }
                        : { label: t('blog.chooseDate'), value: 'date' }
                    }
                    onClick={(value: { label: string; value: string }) => {
                      let disableRepeat = false
                      let toManyTimes = false
                      let toManyDays = false
                      let timesForRepeat: string[] = []

                      if (value.value === 'repeat') {
                        if (dates && dates?.length > 1) {
                          disableRepeat = true
                          toManyDays = true
                          console.log('TEEEEST HEJ')
                        }

                        if (!disableRepeat) {
                          if (times) {
                            for (const time of times) {
                              if (
                                time.start !== undefined &&
                                time.end !== undefined
                              ) {
                                const timePair = `${time.start}-${time.end}`
                                if (timesForRepeat.includes(timePair)) {
                                } else {
                                  timesForRepeat.push(timePair)
                                }
                              }
                            }
                            if (timesForRepeat.length > 1) {
                              disableRepeat = true
                              toManyTimes = true
                            }
                          }
                        }
                      }

                      if (!disableRepeat) {
                        value.value === 'repeat'
                          ? setResponsiveHeight('full')
                          : setResponsiveHeight('96')
                        if (onEditRecurrence) {
                          onEditRecurrence(
                            value.value === 'repeat' ? true : false,
                          )
                        }
                        if (newEvent && recurrence) {
                          const dayIds: string[] = times
                            ? times?.map((time) =>
                                moment(time.date).format('ddd').toLowerCase(),
                              ) ?? []
                            : dates?.map((date) =>
                                moment(date).format('ddd').toLowerCase(),
                              ) ?? []

                          const finalDays = days
                            .filter((day) => dayIds.includes(day.id))
                            .map((day) => day.id)

                          onChangeEvent({
                            ...newEvent,
                            isRecurrence:
                              value.value === 'repeat' ? true : false,
                            recurrence: {
                              ...recurrence,
                              startsOn: dates?.length
                                ? dates[0]
                                : recurrence.startsOn,
                              endsOn: dates?.length
                                ? moment(dates[0])
                                    .add(6, 'months')
                                    .format('YYYY-MM-DD')
                                : recurrence.endsOn,
                              endTime:
                                times?.length && times[0].end
                                  ? times[0].end
                                  : undefined,
                              startTime:
                                times?.length && times[0].start
                                  ? times[0].start
                                  : undefined,
                              days: finalDays,
                            },
                          })
                        }
                      } else {
                        if (toManyTimes) {
                          Swal.fire({
                            title: t('events.toManyTimesTitle'),
                            html: t('events.toManyTimesText'),
                            icon: 'warning',
                            showCancelButton: false,
                            focusConfirm: false,
                            confirmButtonColor: '#7eb5f4',
                            cancelButtonColor: '#d33',
                            confirmButtonText: t('general.ok'),
                          })
                        } else if (toManyDays) {
                          Swal.fire({
                            title: t('events.toManyDaysTitle'),
                            html: t('events.toManyDaysText'),
                            icon: 'warning',
                            showCancelButton: false,
                            focusConfirm: false,
                            confirmButtonColor: '#7eb5f4',
                            cancelButtonColor: '#d33',
                            confirmButtonText: t('general.ok'),
                          })
                        }
                      }
                    }}
                  />
                )}

                {isRecurrence && (
                  <div className="flex flex-col gap-y-8 text-sm align-middle w-full h-full p-2">
                    <div className="flex w-full flex-col gap-y-2">
                      <div className="flex justify-between items-center">
                        <div
                          className={classNames(
                            ' transition duration-300 flex gap-2 items-center',
                          )}
                        >
                          <FontAwesomeIcon icon={faCalendarAlt} />
                          <b>{t('general.startDate')}:</b>
                          {recurrence?.startsOn}
                        </div>
                      </div>
                      <div className="flex justify-between items-center">
                        <div
                          className={classNames(
                            ' transition duration-300 flex gap-2 items-center',
                          )}
                        >
                          <FontAwesomeIcon icon={faCalendarAlt} />
                          <b>{t('general.endDate')}:</b> {recurrence?.endsOn}
                        </div>

                        <div
                          className="text-xs cursor-pointer text-blueDark font-bold"
                          onClick={() => {
                            //setRecurrenceDateSelect('endDate')
                            setEditEndDate(true)
                          }}
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />{' '}
                          {t('absence.change')}
                        </div>
                      </div>
                    </div>
                    {editEndDate && (
                      <ModalRegular
                        isOpen={editEndDate}
                        onClose={() => setEditEndDate(false)}
                        title={t('general.endDate')}
                        className=" max-w-md"
                        classNameTitle="pt-4 w-full text-black flex justify-center"
                        closeColor="white"
                      >
                        <div className="flex flex-col justify-center gap-4 p-4">
                          <Calendar
                            onChangeEvent={() => {}}
                            multiDates={false}
                            dates={[
                              newEvent?.recurrence?.endsOn ??
                                moment().format('YYYY-MM-DD'),
                            ]}
                            onDateClick={(value, lastDate) => {
                              if (newEvent && recurrence) {
                                onChangeEvent({
                                  ...newEvent,
                                  recurrence: {
                                    ...recurrence,
                                    endsOn: lastDate,
                                  },
                                })
                              }
                            }}
                            currentMonth={
                              moment(newEvent?.recurrence?.endsOn) ?? moment()
                            }
                          />
                          <Button
                            size="lg"
                            fontSize="font-bold"
                            className="min-w-[250px] max-w-[50%] my-1.5 m-auto"
                            variant={'fullBlue'}
                            label={t('general.done')}
                            onClick={() => {
                              setEditEndDate(false)
                            }}
                          />
                        </div>
                      </ModalRegular>
                    )}

                    <div className={classNames('overflow-hidden')}>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center font-semibold">
                          <FontAwesomeIcon
                            icon={faArrowsRepeat}
                            className="text-greenSelected"
                          />

                          {/*t('general.repeat')*/}
                          {
                            repeatSelections.find(
                              (rep) => rep.id === recurrence?.interval,
                            )?.label
                          }
                        </div>
                        <div
                          className="text-xs cursor-pointer text-blueDark font-bold"
                          onClick={() => {
                            setEditInterval(!editInterval)
                          }}
                        >
                          {editInterval ? (
                            <>
                              <FontAwesomeIcon icon={faMinus} className="p-2" />
                            </>
                          ) : (
                            <>
                              <FontAwesomeIcon icon={faPenToSquare} />{' '}
                              {t('absence.change')}
                            </>
                          )}
                        </div>
                      </div>
                      {editInterval ? (
                        <motion.div
                          initial={{ height: 0 }}
                          animate={{ height: 'auto' }}
                          className="flex flex-col w-auto divide divide-y-2 rounded-2xl border border-1 mx-2"
                        >
                          {repeatSelections.map((rS) => {
                            return (
                              <div
                                className={classNames(
                                  'flex w-full cursor-pointer p-2 hover:bg-gray-50 text-xs',
                                  rS.id === newEvent?.recurrence?.interval &&
                                    'font-bold',
                                )}
                                onClick={() => {
                                  if (newEvent && newEvent.recurrence) {
                                    onChangeEvent({
                                      ...newEvent,
                                      recurrence: {
                                        ...newEvent.recurrence,
                                        interval: rS.id,
                                      },
                                    })
                                  }
                                  setEditInterval(!editInterval)
                                }}
                              >
                                {rS.label}
                              </div>
                            )
                          })}
                        </motion.div>
                      ) : (
                        <></>
                      )}
                    </div>
                    {allowTimes && (
                      <>
                        <div className="flex justify-between">
                          <div className="flex gap-2 items-center">
                            <FontAwesomeIcon icon={faClock} />{' '}
                            <b>
                              {recurrence?.startTime && recurrence.endTime ? (
                                <>
                                  {recurrence.startTime} - {recurrence.endTime}
                                </>
                              ) : (
                                <>{t('general.chooseTime')}:</>
                              )}
                            </b>
                            <div
                              onClick={() => {
                                if (newEvent && recurrence) {
                                  onChangeEvent({
                                    ...newEvent,
                                    recurrence: {
                                      ...recurrence,
                                      endTime: undefined,
                                      startTime: undefined,
                                    },
                                  })
                                }
                              }}
                              className="text-end cursor-pointer"
                            >
                              {/*t('general.removeTime')
                                <FontAwesomeIcon
                                  icon={faTrashCan}
                                  className="cursor-pointer text-red-600"
                                />*/}
                            </div>
                          </div>
                          <div className="flex gap-4">
                            {recurrence?.startTime && recurrence.endTime ? (
                              <div
                                onClick={() => {
                                  if (newEvent && recurrence) {
                                    onChangeEvent({
                                      ...newEvent,
                                      recurrence: {
                                        ...recurrence,
                                        endTime: undefined,
                                        startTime: undefined,
                                      },
                                    })
                                  }
                                }}
                                className="text-xs cursor-pointer text-red-400 font-semibold"
                              >
                                {t('documents.remove')}
                              </div>
                            ) : (
                              <div
                                onClick={() => {
                                  setShowNewTimeModalRepeat(true)
                                }}
                                className="text-xs cursor-pointer text-blueDark font-bold"
                              >
                                <FontAwesomeIcon icon={faPenToSquare} />{' '}
                                {t('absence.change')}
                              </div>
                            )}
                          </div>
                        </div>
                        <ModalRegular
                          title={t('events.addNewTime')}
                          bgColor="bg-eventsMain p-4"
                          isOpen={showNewTimeModalRepeat}
                          onClose={() => {
                            if (newEvent && recurrence) {
                              onChangeEvent({
                                ...newEvent,
                                recurrence: {
                                  ...recurrence,
                                  endTime: undefined,
                                  startTime: undefined,
                                },
                              })
                              setShowNewTimeModalRepeat(false)
                            }
                          }}
                          className="!max-w-sm !overflow-visible"
                          closeColor="white"
                        >
                          <div className="flex justify-center gap-4 flex-col  p-10">
                            <div className="flex justify-center gap-x-4">
                              <div className="relative">
                                <DatePicker
                                  className="border border-gray-200 shadow p-2 rounded-2xl text-center max-w-[110px] focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                                  selected={
                                    recurrence?.startTime
                                      ? new Date(
                                          moment().format(
                                            'YYYY-MM-DD ' +
                                              recurrence.startTime,
                                          ),
                                        )
                                      : null
                                  }
                                  onChange={(date: Date) => {
                                    if (date === null) {
                                      return
                                    }

                                    if (recurrence && newEvent) {
                                      onChangeEvent({
                                        ...newEvent,
                                        recurrence: {
                                          ...recurrence,
                                          startTime:
                                            moment(date).format('HH:mm'),
                                        },
                                      })
                                    }
                                  }}
                                  minTime={
                                    new Date(
                                      moment().format('YYYY-MM-DD 00:01'),
                                    )
                                  }
                                  maxTime={
                                    recurrence?.endTime
                                      ? new Date(
                                          moment().format(
                                            'YYYY-MM-DD ' + recurrence.endTime,
                                          ),
                                        )
                                      : new Date(
                                          moment().format('YYYY-MM-DD 23:59'),
                                        )
                                  }
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption={t('general.timeOfDay')}
                                  dateFormat="HH:mm"
                                  timeFormat="HH:mm"
                                  locale={i18n.language}
                                />
                              </div>
                              <div className="relative inline-block">
                                <DatePicker
                                  className="border border-gray-200 shadow p-2 rounded-2xl text-center max-w-[110px] focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                                  selected={
                                    recurrence?.endTime
                                      ? new Date(
                                          moment().format(
                                            'YYYY-MM-DD ' + recurrence.endTime,
                                          ),
                                        )
                                      : null
                                  }
                                  onChange={(date: Date) => {
                                    if (date === null) {
                                      return
                                    }
                                    if (recurrence && newEvent) {
                                      onChangeEvent({
                                        ...newEvent,
                                        recurrence: {
                                          ...recurrence,
                                          endTime: moment(date).format('HH:mm'),
                                        },
                                      })
                                    }
                                  }}
                                  minTime={
                                    recurrence?.startTime
                                      ? new Date(
                                          moment().format(
                                            'YYYY-MM-DD ' +
                                              recurrence.startTime,
                                          ),
                                        )
                                      : new Date(
                                          moment().format('YYYY-MM-DD 00:01'),
                                        )
                                  }
                                  maxTime={
                                    new Date(
                                      moment().format('YYYY-MM-DD 23:59'),
                                    )
                                  }
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption={t('general.timeOfDay')}
                                  dateFormat="HH:mm"
                                  timeFormat="HH:mm"
                                  locale={i18n.language}
                                />
                              </div>
                            </div>
                            <div className="flex justify-center gap-x-10 mt-6">
                              <Button
                                variant="danger"
                                size="sm"
                                label={t('general.cancel')}
                                onClick={() => {
                                  if (newEvent && recurrence) {
                                    onChangeEvent({
                                      ...newEvent,
                                      recurrence: {
                                        ...recurrence,
                                        endTime: undefined,
                                        startTime: undefined,
                                      },
                                    })
                                    setShowNewTimeModalRepeat(false)
                                  }
                                }}
                              />
                              <Button
                                variant="fullBlue"
                                size="sm"
                                label={t('general.save')}
                                onClick={() => {
                                  if (
                                    recurrence?.startTime &&
                                    recurrence.endTime
                                  ) {
                                    setShowNewTimeModalRepeat(false)
                                  } else {
                                    Swal.fire({
                                      text: `${t(
                                        'events.setTimeToSelectedSchedueledEvent',
                                      )}`,
                                      icon: 'warning',
                                      showCancelButton: false,
                                      focusConfirm: false,
                                      confirmButtonColor: '#7eb5f4',
                                      cancelButtonColor: '#d33',
                                      confirmButtonText: t('general.ok'),
                                    })
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </ModalRegular>
                      </>
                    )}
                    <div>
                      <div className="w-full font-semibold">
                        {t('general.chooseDay')}
                      </div>
                      <div
                        className={classNames(
                          'w-full mt-2',
                          recurringDaysWave ? 'animate-shake' : '',
                        )}
                      >
                        {days.map((day) => {
                          return (
                            <Button
                              variant={
                                recurrence?.days.includes(day.id)
                                  ? 'fullGreenSelected'
                                  : 'fullWhiteDarkText'
                              }
                              label={day.label}
                              size="xs"
                              className="m-1"
                              onClick={() => {
                                if (newEvent && newEvent.recurrence) {
                                  if (recurrence?.days.includes(day.id)) {
                                    onChangeEvent({
                                      ...newEvent,
                                      recurrence: {
                                        ...newEvent.recurrence,
                                        days: newEvent.recurrence.days.filter(
                                          (recDay) => recDay !== day.id,
                                        ),
                                      },
                                    })
                                  } else {
                                    onChangeEvent({
                                      ...newEvent,
                                      recurrence: {
                                        ...newEvent.recurrence,
                                        days: [
                                          ...newEvent.recurrence.days,
                                          day.id,
                                        ],
                                      },
                                    })
                                  }
                                }
                              }}
                            />
                          )
                        })}
                      </div>
                    </div>
                  </div>
                )}

                {!isRecurrence && (
                  <>
                    {/***************************** No dates choosed *********************************/}
                    {dates && dates?.length < 1 && (
                      <div className="flex flex-col text-center text-sm gap-y-6 mt-10 text-gray-400">
                        <FontAwesomeIcon
                          icon={faCalendarAlt}
                          className=" text-8xl"
                        />
                        {t('events.sceduleAnEvent')}
                      </div>
                    )}
                    {/***************************** At least one date choosed buttons for add, add many, edit *********************************/}

                    {selectedDates.length > 0 &&
                      newEvent?.eventType !== 'info' && (
                        <>
                          <div
                            className={`flex flex-row justify-between items-center text-center w-full p-2 h-20 transition-all duration-1000 ease-in-out gap-x-1 animate-fadeIn ${
                              selectedDates.length > 0
                                ? 'opacity-100 translate-y-0'
                                : 'opacity-0 -translate-y-0'
                            }`}
                          >
                            <div className="w-[30%]">
                              <Button
                                className="flex items-center justify-center"
                                variant="custom"
                                textColor="black"
                                size="sm"
                                icon={faClock}
                                onClick={() => {
                                  if (!selectedDates.length) {
                                    Swal.fire({
                                      text: `${t(
                                        'events.addTimeToScheduledEvent',
                                      )}`,
                                      icon: 'warning',
                                      showCancelButton: false,
                                      focusConfirm: false,
                                      confirmButtonColor: '#7eb5f4',
                                      cancelButtonColor: '#d33',
                                      confirmButtonText: t('general.ok'),
                                    })
                                  } else {
                                    setShowNewTimeModal(true)
                                  }
                                }}
                              />
                              <p className="text-xs mt-1">
                                {t('events.addTime')}
                              </p>
                            </div>
                            {newEvent?.eventType === EVENT3_TYPE.BOOKING && (
                              <div className="w-[30%]">
                                <Button
                                  className="flex items-center justify-center"
                                  variant="custom"
                                  textColor="black"
                                  fontSize="xs2"
                                  size="sm"
                                  icon={faClock}
                                  iconColor="text-red-300"
                                  onClick={() => {
                                    if (!selectedDates.length) {
                                      Swal.fire({
                                        text: `${t(
                                          'events.addTimeToScheduledEvent',
                                        )}`,
                                        icon: 'warning',
                                        showCancelButton: false,
                                        focusConfirm: false,
                                        confirmButtonColor: '#7eb5f4',
                                        cancelButtonColor: '#d33',
                                        confirmButtonText: t('general.ok'),
                                      })
                                    } else {
                                      setShowMultipleTimes(true)
                                    }
                                  }}
                                />
                                <p className="text-xs mt-1">
                                  <>{t('events.addMoreTimes')}</>
                                </p>
                              </div>
                            )}
                            <div className="w-[30%]">
                              <Button
                                className="flex items-center justify-center"
                                variant="custom"
                                textColor="black"
                                iconColor="text-orange-400"
                                fontSize="xs"
                                size="sm"
                                icon={faPenToSquare}
                                onClick={() => {
                                  if (!selectedDates.length) {
                                    Swal.fire({
                                      text: `${t('events.editScheduledEvent')}`,
                                      icon: 'warning',
                                      showCancelButton: false,
                                      focusConfirm: false,
                                      confirmButtonColor: '#7eb5f4',
                                      cancelButtonColor: '#d33',
                                      confirmButtonText: t('general.ok'),
                                    })
                                  } else {
                                    setShowEditDateModal(true)
                                  }
                                }}
                              />
                              <p className="text-xs mt-1">{t('notes.edit')}</p>
                            </div>
                          </div>
                        </>
                      )}

                    {allowTimes ? (
                      /** TODO: Dela upp i komponenter valda tider, selectall/deselectall, modalregualr för 3 alternative [addtime, add many, edit]*/
                      <>
                        {groupedTimes?.length && groupedTimes.length > 1 ? (
                          <div className="flex justify-end gap-x-2 items-center px-3 py-1">
                            <div className="text-xs font-semibold text-[#1398F7] animate-fadeIn">
                              {selectedDates.length === groupedTimes?.length
                                ? t('multiselect.deSelectAll')
                                : t('multiselect.selectAll')}
                            </div>
                            <div
                              className={classNames(
                                'w-4 h-4 rounded-full border justify-center flex items-center cursor-pointer',
                                selectedDates.length === groupedTimes?.length
                                  ? 'bg-[#1398F7] border-[#1398F7]'
                                  : 'border-gray-500 bg-white',
                              )}
                              onClick={() => {
                                if (
                                  selectedDates.length === groupedTimes?.length
                                ) {
                                  setSelectedDates([])
                                } else {
                                  setSelectedDates(groupedTimes)
                                }
                              }}
                            >
                              {selectedDates.length ===
                                groupedTimes?.length && (
                                <FontAwesomeIcon
                                  color="#ffffff"
                                  icon={faCheck}
                                  size="2xs"
                                />
                              )}
                            </div>
                          </div>
                        ) : null}
                        <div
                          className={`w-full overflow-auto gap-y-2 h-full  relative `}
                        >
                          <div className="sticky top-0 left-0 right-0  z-10 pointer-events-noneabsolute h-[10%] bg-gradient-to-b from-white to-[rgba(255,255,255,0)] w-full rounded-b-xl"></div>

                          {groupedTimes?.map((groupDate, index) => {
                            const timesToShow = times?.filter(
                              (time) => time.date === groupDate,
                            )
                            console.log(
                              '----------->',
                              groupedTimes.includes(groupDate),
                            )
                            console.log('*****************>', slideOutDate)

                            /* valda tider right column  */
                            return (
                              <div
                                className={`flex flex-row justify-between w-full gap-x-3 items-center pt-1  
                                  animate-slideIn cursor-pointer ${
                                    index === 0 ? 'mt-[-20px]' : ''
                                  }
                                `}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  setSelectedDates(
                                    selectedDates.find(
                                      (date) => date === groupDate,
                                    )
                                      ? selectedDates.filter(
                                          (date) => date !== groupDate,
                                        )
                                      : [...selectedDates, groupDate],
                                  )
                                }}
                              >
                                <div className="justify-between items-center inline-flex shadow-md pt-1 px-3 rounded-lg select-none w-full">
                                  <CalendarDateIcon date={groupDate} />
                                  <div className="relative max-h-24">
                                    <div className="absolute top-0 left-0 right-0 h-6 bg-gradient-to-b from-white to-transparent z-10 pointer-events-none"></div>
                                    <div className=" max-h-24 overflow-y-scroll py-3 w-full px-3">
                                      <ul className="text-sm text-[#1398F7] bg-ed-300 w-full">
                                        {timesToShow?.map((time, index) => (
                                          <>
                                            {time.start && time.end ? (
                                              <li>{`${time.start} - ${time.end}`}</li>
                                            ) : (
                                              <div className="flex items-center h-14">
                                                <p className="text-xs">
                                                  {t('events.addTime')}
                                                </p>
                                              </div>
                                            )}
                                          </>
                                        ))}
                                      </ul>
                                    </div>
                                    <div className="absolute bottom-0 left-0 right-0 h-6 bg-gradient-to-t from-white to-transparent z-10 pointer-events-none"></div>
                                  </div>
                                  <div
                                    className={classNames(
                                      'w-4 h-4 rounded-full border justify-center flex items-center cursor-pointer',
                                      selectedDates.find(
                                        (date) => date === groupDate,
                                      )
                                        ? 'bg-[#1398F7] border-[#1398F7]'
                                        : 'border-gray-500 bg-white',
                                    )}
                                  >
                                    {selectedDates.find(
                                      (date) => date === groupDate,
                                    ) && (
                                      <FontAwesomeIcon
                                        color="#ffffff"
                                        icon={faCheck}
                                        size="2xs"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                          {groupedTimes && groupedTimes.length > 3 && (
                            <div className="sticky h-[15%] bg-gradient-to-t from-white to-[rgba(255,255,255,0)] bottom-0 left-0 w-full flex justify-center items-center rounded-b-xl"></div>
                          )}
                        </div>

                        <div className="flex justify-between">
                          <ModalRegular
                            title={`${
                              multipleTimesStep === 1
                                ? t('events.selectStartTime')
                                : multipleTimesStep === 2
                                ? t('events.lengthOfMeeting')
                                : multipleTimesStep === 3
                                ? t('events.pauseInbetweenMeetings')
                                : multipleTimesStep === 4
                                ? t('events.numberOfMeetings')
                                : ''
                            }`}
                            isOpen={showMultipleTimes}
                            closeColor="white"
                            onClose={() => {
                              setShowMultipleTimes(false)
                              setMultipleTimesStep(1)
                              setMultipleTimes(initialMultipleTimesValues)
                            }}
                            className="max-w-xl !overflow-visible"
                            classNameTitle="p-4 w-full text-black flex justify-center"
                          >
                            <div className="flex justify-center gap-4 flex-col  p-10">
                              <div className="flex justify-center gap-x-4">
                                <div className="relative justify-center items-center flex flex-col">
                                  {multipleTimesStep === 1 && (
                                    <>
                                      <div className="font-bold text-xl mb-4"></div>
                                      <DatePicker
                                        className="border border-gray-200 shadow p-2 rounded-2xl text-center max-w-[110px] focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                                        selected={
                                          new Date(
                                            moment().format(
                                              'YYYY-MM-DD ' +
                                                multipleTimes.start,
                                            ),
                                          )
                                        }
                                        onChange={(date: Date) => {
                                          if (date === null) {
                                            return
                                          }
                                          setMultipleTimes((prevState) => ({
                                            ...prevState,
                                            start: moment(date).format('HH:mm'),
                                          }))
                                          //setNewKid((prevState) => ({ ...prevState, kidName: displayName }))
                                        }}
                                        minTime={
                                          new Date(
                                            moment().format('YYYY-MM-DD 00:01'),
                                          )
                                        }
                                        maxTime={
                                          new Date(
                                            moment().format('YYYY-MM-DD 23:59'),
                                          )
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption={t('general.timeOfDay')}
                                        dateFormat="HH:mm"
                                        timeFormat="HH:mm"
                                        locale={i18n.language}
                                      />
                                    </>
                                  )}
                                  {multipleTimesStep === 2 && (
                                    <>
                                      <Singleselect
                                        items={lengthsToMeeting}
                                        onChange={(value) => {
                                          console.log('value', value)
                                          setMultipleTimes((prevState) => ({
                                            ...prevState,
                                            length: value?.value,
                                          }))
                                        }}
                                        initialSelectedItem={{
                                          value: multipleTimes.length,
                                          label: multipleTimes.length,
                                        }}
                                        itemVariableKey="label"
                                        placeholder="Hejsan"
                                      />
                                    </>
                                  )}
                                  {multipleTimesStep === 3 && (
                                    <>
                                      <Singleselect
                                        items={lengthsToMeeting}
                                        onChange={(value) => {
                                          console.log('value', value)
                                          setMultipleTimes((prevState) => ({
                                            ...prevState,
                                            pause: value?.value,
                                          }))
                                        }}
                                        initialSelectedItem={{
                                          value: multipleTimes.pause,
                                          label: multipleTimes.pause,
                                        }}
                                        itemVariableKey="label"
                                        placeholder="Hejsan"
                                      />
                                    </>
                                  )}
                                  {multipleTimesStep === 4 && (
                                    <>
                                      <Singleselect
                                        items={countToMeeting}
                                        onChange={(value) => {
                                          console.log('value', value)
                                          setMultipleTimes((prevState) => ({
                                            ...prevState,
                                            amount: value?.value,
                                          }))
                                        }}
                                        initialSelectedItem={{
                                          value: multipleTimes.amount,
                                          label: multipleTimes.amount,
                                        }}
                                        itemVariableKey="label"
                                        placeholder="Hejsan"
                                      />
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="flex justify-center gap-x-4 mt-2">
                                <Button
                                  variant="fullRed"
                                  size="sm"
                                  label={
                                    multipleTimesStep === 1
                                      ? t('general.cancel')
                                      : t('login.back')
                                  }
                                  onClick={() => {
                                    if (multipleTimesStep > 1) {
                                      setMultipleTimesStep(
                                        multipleTimesStep - 1,
                                      )
                                    } else {
                                      setShowMultipleTimes(false)
                                      setMultipleTimesStep(1)
                                      setMultipleTimes(
                                        initialMultipleTimesValues,
                                      )
                                    }
                                  }}
                                />
                                <Button
                                  variant="fullBlue"
                                  size="sm"
                                  label={
                                    multipleTimesStep === 4
                                      ? t('general.done')
                                      : t('login.nextTitle')
                                  }
                                  onClick={async () => {
                                    if (multipleTimesStep === 4) {
                                      if (newEvent?.times) {
                                        let allTimes = newEvent.times ?? []
                                        const { newTimes, dates } =
                                          createManyEventTimesForBooking(
                                            multipleTimes,
                                            newEvent.times.filter((time) =>
                                              selectedDates.includes(time.date),
                                            ),
                                          )

                                        for (const newT of newTimes) {
                                          allTimes.push(newT)
                                        }

                                        const dontRemove =
                                          newEvent.times.filter(
                                            (time) =>
                                              !selectedDates.includes(
                                                time.date,
                                              ),
                                          )

                                        allTimes = allTimes
                                          .filter((aT) =>
                                            dates.includes(aT.date),
                                          )
                                          .filter((aT) => aT.start)
                                        if (dontRemove && dontRemove.length) {
                                          allTimes.push(...dontRemove)
                                        }

                                        onChangeEvent({
                                          ...newEvent,
                                          times: allTimes,
                                        })
                                        setShowMultipleTimes(false)
                                        setMultipleTimesStep(1)
                                        setMultipleTimes(
                                          initialMultipleTimesValues,
                                        )
                                      }
                                    } else {
                                      setMultipleTimesStep(
                                        multipleTimesStep + 1,
                                      )
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </ModalRegular>
                          <ModalRegular
                            title={t('events.addNewTime')}
                            isOpen={showNewTimeModal}
                            onClose={() => setShowNewTimeModal(false)}
                            closeColor="white"
                            className="max-w-xl !overflow-visible"
                            classNameTitle="p-4 w-full text-black flex justify-center"
                          >
                            <div className="flex justify-center gap-4 flex-col  p-10">
                              <div className="flex justify-center gap-x-4">
                                <div className="relative">
                                  <DatePicker
                                    className="border border-gray-200 shadow p-2 rounded-2xl text-center max-w-[110px] focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                                    selected={
                                      new Date(
                                        moment().format(
                                          'YYYY-MM-DD ' + newTime.start,
                                        ),
                                      )
                                    }
                                    onChange={(date: Date) => {
                                      if (date === null) {
                                        return
                                      }

                                      setNewTime({
                                        start: moment(date).format('HH:mm'),
                                        end: newTime.end,
                                      })
                                    }}
                                    minTime={
                                      new Date(
                                        moment().format('YYYY-MM-DD 00:01'),
                                      )
                                    }
                                    maxTime={
                                      new Date(
                                        moment().format(
                                          'YYYY-MM-DD ' + newTime.end,
                                        ),
                                      )
                                    }
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption={t('general.timeOfDay')}
                                    dateFormat="HH:mm"
                                    timeFormat="HH:mm"
                                    locale={i18n.language}
                                  />
                                </div>
                                <div className="relative inline-block">
                                  <DatePicker
                                    className="border border-gray-200 shadow p-2 rounded-2xl text-center max-w-[110px] focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                                    selected={
                                      new Date(
                                        moment().format(
                                          'YYYY-MM-DD ' + newTime.end,
                                        ),
                                      )
                                    }
                                    onChange={(date: Date) => {
                                      if (date === null) {
                                        return
                                      }
                                      setNewTime({
                                        start: newTime.start,
                                        end: moment(date).format('HH:mm'),
                                      })
                                    }}
                                    minTime={
                                      new Date(
                                        moment().format(
                                          'YYYY-MM-DD ' + newTime.start,
                                        ),
                                      )
                                    }
                                    maxTime={
                                      new Date(
                                        moment().format('YYYY-MM-DD 23:59'),
                                      )
                                    }
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption={t('general.timeOfDay')}
                                    dateFormat="HH:mm"
                                    timeFormat="HH:mm"
                                    locale={i18n.language}
                                  />
                                </div>
                              </div>
                              <div className="flex justify-center gap-x-4">
                                <Button
                                  variant="fullRed"
                                  size="sm"
                                  label={t('general.cancel')}
                                  onClick={() => {
                                    setShowNewTimeModal(false)
                                  }}
                                />
                                <Button
                                  variant="fullBlue"
                                  size="sm"
                                  label={t('general.save')}
                                  onClick={async () => {
                                    if (onAddTime && selectedDates) {
                                      await onAddTime(newTime, selectedDates)
                                      setShowNewTimeModal(false)
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </ModalRegular>
                          <ModalRegular
                            title={t('blog.edit')}
                            isOpen={showEditDateModal}
                            onClose={() => {
                              setShowEditDateModal(false)
                              if (times) {
                                setEditTimes(times)
                              }
                            }}
                            closeColor="white"
                            className=" max-w-sm h-[500px]"
                            classNameTitle="p-4 w-full text-black flex justify-center"
                          >
                            <div className="px-10 h-[500px] relative">
                              <div className="pb-32 h-full overflow-y-auto">
                                {groupedTimes
                                  ?.filter((gD) => selectedDates.includes(gD))
                                  .map((groupDate) => {
                                    const timesToShow = editTimes?.filter(
                                      (time) => time.date === groupDate,
                                    )
                                    if (
                                      !timesToShow.find(
                                        (time) => time.date === groupDate,
                                      )
                                    ) {
                                      return null
                                    }
                                    return (
                                      <div
                                        className={`flex gap-y-4 flex-col justify-between mb-3`}
                                      >
                                        <div className="flex mt-4">
                                          <b>
                                            {moment(groupDate).format(
                                              'YYYY-MM-DD',
                                            )}
                                          </b>
                                        </div>
                                        <div className="flex flex-col justify-between items-center gap-y-2">
                                          {timesToShow?.map((time, index) => (
                                            <div
                                              className="w-full flex justify-between items-center p-2 hover:bg-gray-100 rounded-xl"
                                              key={index}
                                            >
                                              <div className="text-sm">
                                                {time.start && time.end ? (
                                                  `${time.start} - ${time.end}`
                                                ) : (
                                                  <>{t('events.noSetTime')}</>
                                                )}
                                              </div>
                                              <div className="flex flex-row gap-x-2">
                                                <Button
                                                  variant="fullRed"
                                                  size="xs"
                                                  icon={faTrashCan}
                                                  onClick={() => {
                                                    //  onRemove()
                                                    setEditTimes(
                                                      editTimes.filter(
                                                        (eT) =>
                                                          eT.fakeId !==
                                                          time.fakeId,
                                                      ),
                                                    )
                                                  }}
                                                />
                                                <Button
                                                  variant="fullBlue"
                                                  size="xs"
                                                  icon={faPen}
                                                  onClick={() => {
                                                    setEditTime(time)
                                                    //onSaveLocationModal()
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    )
                                  })}
                              </div>
                              <div className="flex justify-center gap-x-3 p-2 bg-gradient-to-t from-white fixed bottom-0 left-0 w-full">
                                <Button
                                  size="sm"
                                  variant="fullRed"
                                  label={t('general.cancel')}
                                  onClick={() => {
                                    setShowEditDateModal(false)
                                    if (times) {
                                      setEditTimes(times)
                                    }
                                  }}
                                />
                                <Button
                                  size="sm"
                                  variant="fullBlue"
                                  label={t('general.done')}
                                  onClick={() => {
                                    if (onEditTimes) {
                                      onEditTimes(editTimes)
                                    }
                                    setShowEditDateModal(false)
                                    /*if (onAddTime && selectedDates) {
                                    onAddTime(newTime, selectedDates)
                                    }*/
                                  }}
                                />
                              </div>
                            </div>
                            {/* Redigera tid modul */}
                            {editTime && (
                              <ModalRegular
                                title={t('blog.edit')}
                                isOpen={editTime ? true : false}
                                onClose={() => setEditTime(null)}
                                closeColor="white"
                                className="overflow-visible max-w-sm"
                                classNameTitle="p-4 w-full text-black flex justify-center"
                              >
                                <div className="flex justify-center gap-4 flex-col p-6">
                                  <div className="flex justify-center gap-x-4">
                                    <div className="relative">
                                      <DatePicker
                                        className="border border-gray-200 shadow p-2 rounded-2xl text-center max-w-[110px] focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                                        selected={
                                          editTime.start
                                            ? new Date(
                                                moment().format(
                                                  'YYYY-MM-DD ' +
                                                    editTime.start,
                                                ),
                                              )
                                            : null
                                        }
                                        onChange={(date: Date) => {
                                          if (date === null) {
                                            return
                                          }
                                          setEditTime({
                                            start: moment(date).format('HH:mm'),
                                            end: editTime.end,
                                            date: editTime.date,
                                            fakeId: editTime.fakeId,
                                          })
                                        }}
                                        minTime={
                                          new Date(
                                            moment().format('YYYY-MM-DD 00:01'),
                                          )
                                        }
                                        maxTime={
                                          editTime.end
                                            ? new Date(
                                                moment().format(
                                                  'YYYY-MM-DD ' + editTime.end,
                                                ),
                                              )
                                            : new Date(
                                                moment().format(
                                                  'YYYY-MM-DD 23:59',
                                                ),
                                              )
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption={t('general.timeOfDay')}
                                        dateFormat="HH:mm"
                                        timeFormat="HH:mm"
                                        locale={i18n.language}
                                      />
                                    </div>
                                    <div className="relative inline-block">
                                      <DatePicker
                                        className="border border-gray-200 shadow p-2 rounded-2xl text-center max-w-[110px] focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
                                        selected={
                                          editTime.end
                                            ? new Date(
                                                moment().format(
                                                  'YYYY-MM-DD ' + editTime.end,
                                                ),
                                              )
                                            : null
                                        }
                                        onChange={(date: Date) => {
                                          if (date === null) {
                                            return
                                          }
                                          setEditTime({
                                            start: editTime.start,
                                            end: moment(date).format('HH:mm'),
                                            date: editTime.date,
                                            fakeId: editTime.fakeId,
                                          })
                                        }}
                                        minTime={
                                          editTime.start
                                            ? new Date(
                                                moment().format(
                                                  'YYYY-MM-DD ' +
                                                    editTime.start,
                                                ),
                                              )
                                            : new Date(
                                                moment().format(
                                                  'YYYY-MM-DD 00:01',
                                                ),
                                              )
                                        }
                                        maxTime={
                                          new Date(
                                            moment().format('YYYY-MM-DD 23:59'),
                                          )
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption={t('general.timeOfDay')}
                                        dateFormat="HH:mm"
                                        timeFormat="HH:mm"
                                        locale={i18n.language}
                                      />
                                    </div>
                                  </div>
                                  <div className="flex justify-center gap-x-4">
                                    <Button
                                      size="sm"
                                      variant="fullRed"
                                      label={t('general.cancel')}
                                      onClick={() => {
                                        setEditTime(null)
                                      }}
                                    />
                                    <Button
                                      size="sm"
                                      variant="fullBlue"
                                      label={t('general.save')}
                                      onClick={async () => {
                                        const newEditTimes = editTimes.map(
                                          (time) => {
                                            if (
                                              editTime.fakeId === time.fakeId
                                            ) {
                                              return editTime
                                            } else {
                                              return time
                                            }
                                          },
                                        )
                                        await setEditTimes(newEditTimes)
                                        setEditTime(null)
                                        /*if (onAddTime && selectedDates) {
                                  onAddTime(newTime, selectedDates)
                                }*/
                                      }}
                                    />
                                  </div>
                                </div>
                              </ModalRegular>
                            )}
                            {/* Slut redigera tid modul */}
                          </ModalRegular>
                        </div>
                      </>
                    ) : (
                      <div className="h-96 overflow-auto px-1">
                        <div className="flex  flex-wrap gap-2  justify-start ">
                          {dates
                            ?.sort((a, b) => {
                              return (
                                new Date(a).getTime() - new Date(b).getTime()
                              )
                            })
                            .map((date) => {
                              return (
                                <div className="shadow-sm p-2 rounded-2xl animate-slideIn bg-[#F9FAFB]">
                                  <CalendarDateIcon date={date} />
                                </div>
                              )
                            })}
                        </div>
                        {dates && dates?.length > 15 && (
                          <div className="sticky h-[6vh] bg-gradient-to-t from-white to-[rgba(255,255,255,0)] bottom-0 left-0 w-full rounded-2xl"></div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
