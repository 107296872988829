import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { Button } from '../../../components/Button'
import { observer } from 'mobx-react'
import {
  Event3StoreContext,
  EventTimeSmallInterface,
  IsoDate,
  ListToBookMeetingResponse,
} from '../../../store/event3Store'
import { useTranslation } from 'react-i18next'
import {
  IconDefinition,
  faArrowLeft,
  faCalendar,
  faCalendarDay,
  faCheck,
  faComment,
  faEnvelope,
  faExclamation,
} from '@fortawesome/free-solid-svg-icons'
import { Loading } from '../../../components/Loading'
import { FreeTimesCard } from './FreeTimesCard'
import ModalRegular from '../../../components/ModalRegular'
import { UserStoreContext } from '../../../store/userStore'
import { KidsStoreContext } from '../../../store/kidsStore'
import { getImageOrPlaceHolder } from '../../../utils/parseUtils'
import { UserRole } from '../../../Models/UserRole'
import classNames from 'classnames'
import { getDaysFullForTranslatation } from '../../events/components/EventsInfo'
import { LocationBox } from './LocationBox'
import { USER_ROLE_TYPE } from '../../../Enums/TyraEnums'
import { TabHeaderSlider } from '../../documents/DocumentMain'
import { KidBookingCard } from './KidBookingCard'
import { Kid } from '../../../Models/Kid'
import { BookingFinalStage } from './BookingFinalStage'
import Swal from 'sweetalert2'
import { ChooseKids } from './ChooseKids'
import { DepartmentStoreContext } from '../../../store/departmentStore'
import { EventTagLabel } from './EventTagLabel'
import { getFullMonthForTranslatation } from '../CreateEvent3'

interface Props extends React.PropsWithChildren {
  type?: 'events3'
  date?: IsoDate
  onClose: () => void
}

interface MeetingsCardProps extends React.PropsWithChildren {
  eventInfo?: ListToBookMeetingResponse
  onClick: () => void
  isStaff?: Boolean
}

export const MeetingsCard: React.FC<MeetingsCardProps> = observer(
  ({ eventInfo, onClick, isStaff }) => {
    const { t } = useTranslation()
    return (
      <>
        <div
          className={classNames(
            'rounded-2xl border border-purpleChildren p-4 w-full cursor-pointer relative',
            isStaff && 'pb-[40px]',
          )}
          onClick={() => onClick()}
        >
          <div className="absolute top-4 right-4">
            <div className="text-2xs font-bold">
              {moment(eventInfo?.fromDate).format('DD/MM YYYY')}
              {eventInfo?.fromDate !== eventInfo?.toDate ? (
                <>- {moment(eventInfo?.toDate).format('DD/MM YYYY')}</>
              ) : null}
            </div>
            <div className="text-xs font-bold"></div>
          </div>
          {eventInfo?.tags?.length ? (
            <div className="flex w-full mt-2">
              {eventInfo?.tags?.map((tag) => {
                return (
                  <EventTagLabel
                    label={tag.name ?? ''}
                    color={tag.color}
                    onlyStaff={tag.only_staff}
                  />
                )
              })}
            </div>
          ) : null}

          <div className="flex w-full mt-2">
            <div className="font-bold text-sm">{eventInfo?.topic}</div>
          </div>
          <div className="flex w-full mt-2">
            <div className="text-sm">{eventInfo?.content}</div>
          </div>
          <div className="flex w-full mt-2">
            <div className="text-2xs font-bold">
              {t('events.responsible')}:{' '}
              {eventInfo?.eventResponsible?.map((resp, index) => {
                return (
                  <>
                    {resp.title}
                    {eventInfo.eventResponsible?.length &&
                    index < eventInfo.eventResponsible?.length - 1
                      ? ', '
                      : null}
                  </>
                )
              })}
            </div>
          </div>
          {isStaff && (
            <div className="grid grid-cols-4 w-full mt-2 absolute left-0 bottom-2 text-xs">
              <div className="flex justify-center font-bold items-center">
                <div className="w-[20px] h-[20px] rounded-full bg-greenSelected mr-1 flex items-center justify-center">
                  <FontAwesomeIcon icon={faCheck} className="text-white" />
                </div>
                <div>{eventInfo?.statusSummary?.attending}</div>
              </div>
              <div className="flex justify-center font-bold items-center">
                <div className="w-[20px] h-[20px] rounded-full bg-blueDark mr-1 flex items-center justify-center">
                  <FontAwesomeIcon icon={faCalendar} className="text-white" />
                </div>
                <div>{eventInfo?.statusSummary?.free_times}</div>
              </div>
              <div className="flex flew-row justify-center font-bold items-center">
                <div className="w-[20px] h-[20px] rounded-full bg-purpleChildren mr-1 flex items-center justify-center">
                  <FontAwesomeIcon
                    icon={faExclamation}
                    className="text-white"
                  />
                </div>
                <div>{eventInfo?.statusSummary?.not_replied}</div>
              </div>
              <div className="flex justify-center font-bold items-center">
                <div className="w-[20px] h-[20px] rounded-full bg-gray80 mr-1 flex items-center justify-center">
                  <FontAwesomeIcon icon={faEnvelope} className="text-white" />
                </div>
                <div>{eventInfo?.statusSummary?.messages}</div>
              </div>
            </div>
          )}
        </div>
      </>
    )
  },
)

export const MeetingsToBook: React.FC<Props> = observer(
  ({ type, date, onClose }) => {
    const { t } = useTranslation()
    const [showSpecificMeeting, setShowSpecificMeeting] = useState<
      string | null
    >(null)
    const [selectedTime, setSelectedTime] =
      useState<EventTimeSmallInterface | null>(null)
    const [loading, setLoading] = useState(false)
    const [showFinalStage, setShowFinalStage] = useState(false)
    const [showFinalStageStaff, setShowFinalStageStaff] = useState(false)
    const [presentGuardians, setPresentGuardians] = useState<UserRole[]>([])
    const [message, setMessage] = useState('')
    const [chooseTime, setChooseTime] = useState(false)
    const [chooseKid, setChooseKid] = useState(false)
    const [selectedKid, setSelectedKid] = useState<undefined | Kid>(undefined)
    const [reminderSentTo, setReminderSentTo] = useState<string[]>([])

    const {
      getListToBookMeeting,
      listToBookMeeting,
      getDetailedInfoFromGroupInfoId,
      detailedInfoFromGroupInfoId,
      getEvents,
      meetingsToBook,
      currentMeetings,
      sendReminder,
    } = useContext(Event3StoreContext)
    const { currentUserRole } = useContext(UserStoreContext)
    const { fetchGuardiansForKids, guardians, kids, fetchKids } =
      useContext(KidsStoreContext)
    const { acceptParticipatingInEvent } = useContext(Event3StoreContext)
    const { fetchDepartments, departments } = useContext(DepartmentStoreContext)

    const [activeTab, setActiveTab] = useState<{
      label: string
      value: string
      icon?: IconDefinition
      color?: string
    }>({
      label: `(${detailedInfoFromGroupInfoId?.bookingOverview?.bookedMeetings?.length})`,
      value: 'attending',
      color: '#8ED974',
      icon: faCheck,
    })
    const [wave, setWave] = useState(false)
    const triggerWave = () => {
      setWave(true)
      setTimeout(() => {
        setWave(false)
      }, 1000)
    }
    useEffect(() => {
      if (isStaff) {
        fetchKids(currentUserRole)
      } else {
        if (currentUserRole && currentUserRole.kid) {
          fetchGuardiansForKids(currentUserRole.kid)
        }
      }
    }, [currentUserRole])

    useEffect(() => {
      if (currentUserRole) {
        fetchDepartments(currentUserRole.school_pointer.id, true)
      }
    }, [])

    useEffect(() => {
      if (selectedKid) {
        fetchGuardiansForKids(selectedKid)
      }
    }, [selectedKid])

    useEffect(() => {
      if (meetingsToBook?.length === 1) {
        setLoading(true)
        setShowSpecificMeeting(meetingsToBook[0])
        getDetailedInfoFromGroupInfoId(meetingsToBook[0]).finally(() => {
          setLoading(false)
        })
      } else {
        getListToBookMeeting()
      }
    }, [])
    const isStaff =
      currentUserRole && currentUserRole?.role_type > USER_ROLE_TYPE.PARENT
    const showBack = isStaff
      ? currentMeetings && currentMeetings.length > 1
      : meetingsToBook && meetingsToBook?.length > 1
    let datesWrittenArray: string[] = []
    let monthsWrittenArray: string[] = []
    return (
      <>
        <div className="flex w-full p-4 text-center justify-center bg-purpleChildren text-white font-bold">
          {showSpecificMeeting ? t('events.bookAMeeting') : t('events.booking')}
        </div>
        <div className="flex p-6 min-h-[200px]">
          {loading ? (
            <Loading />
          ) : (
            <>
              {showSpecificMeeting ? (
                <>
                  <div className="flex-col w-full">
                    <div className="w-full mb-4">
                      {showBack && (
                        <div
                          className="text-xs font-semibold cursor-pointer w-full"
                          onClick={() => setShowSpecificMeeting(null)}
                        >
                          <FontAwesomeIcon icon={faArrowLeft} />{' '}
                          {t('events.backToList')}
                        </div>
                      )}
                    </div>
                    <div className="flex w-full">
                      <div className="flex flex-1 text-lg font-bold">
                        {detailedInfoFromGroupInfoId?.topic}
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="flex flex-1">
                        {detailedInfoFromGroupInfoId?.content}
                      </div>
                    </div>
                    {detailedInfoFromGroupInfoId?.location && (
                      <div className="flex w-full mt-3">
                        <div className="flex flex-1 text-sm">
                          <LocationBox
                            noPadding
                            hideOnSave
                            hideRemove
                            showIcon
                            onRemove={() => {}}
                            onSaveLocationModal={() => {}}
                            location={detailedInfoFromGroupInfoId?.location}
                          />
                        </div>
                      </div>
                    )}
                    <div className="flex w-full mt-3">
                      <div className="text-sm">
                        <span className="font-bold">
                          {t('events.responsible')}:
                        </span>
                        <br />
                        {detailedInfoFromGroupInfoId?.eventResponsible?.map(
                          (resp, index) => {
                            return (
                              <>
                                {resp.title}
                                {detailedInfoFromGroupInfoId.eventResponsible
                                  ?.length &&
                                index <
                                  detailedInfoFromGroupInfoId.eventResponsible
                                    ?.length -
                                    1
                                  ? ', '
                                  : null}
                              </>
                            )
                          },
                        )}
                      </div>
                    </div>
                    {isStaff ? (
                      <>
                        <div className="flex w-full">
                          <div className="w-full">
                            <TabHeaderSlider
                              tabs={[
                                {
                                  label: `(${detailedInfoFromGroupInfoId?.bookingOverview?.bookedMeetings?.length})`,
                                  value: 'attending',
                                  color: '#8ED974',
                                  icon: faCheck,
                                },
                                {
                                  label: `(${detailedInfoFromGroupInfoId?.bookingOverview?.freeTimes?.length})`,
                                  value: 'free_times',
                                  color: '#1398f7',
                                  icon: faCalendar,
                                },
                                {
                                  label: `(${detailedInfoFromGroupInfoId?.bookingOverview?.kidsNotBooked?.length})`,
                                  value: 'not_booked',
                                  color: '#c99cf2',
                                  icon: faExclamation,
                                },
                                {
                                  label: `(${detailedInfoFromGroupInfoId?.bookingOverview?.messages?.length})`,
                                  value: 'messages',
                                  color: '#505050',
                                  icon: faComment,
                                },
                              ]}
                              onClick={(value) => {
                                setActiveTab(value)
                              }}
                              activeTab={activeTab}
                            />
                          </div>
                        </div>

                        {activeTab.value === 'attending' && (
                          <>
                            <div className="w-full text-center font-bold p-4">
                              {t('events.bookedMeetings')}
                            </div>
                            <div className="grid grid-cols-2 w-full gap-3">
                              {detailedInfoFromGroupInfoId?.bookingOverview?.bookedMeetings
                                .slice()
                                .sort((a, b) => {
                                  // Ensure both dates are valid before comparison
                                  const dateA = new Date(a.date!)
                                  const dateB = new Date(b.date!)

                                  // First, compare by start_date
                                  if (dateA.getTime() !== dateB.getTime()) {
                                    return dateA.getTime() - dateB.getTime() // Sort by date first
                                  }

                                  // If the dates are the same, compare by start_time
                                  const timeA = a.startTime
                                    ? a.startTime.split(':').map(Number)
                                    : [0, 0]
                                  const timeB = b.startTime
                                    ? b.startTime.split(':').map(Number)
                                    : [0, 0]

                                  // Compare hours first, then minutes
                                  if (timeA[0] !== timeB[0]) {
                                    return timeA[0] - timeB[0] // Compare hours
                                  }

                                  return timeA[1] - timeB[1] // Compare minutes
                                })
                                .map((object) => {
                                  let newDate = false
                                  if (
                                    !datesWrittenArray.find(
                                      (date) =>
                                        date ===
                                        moment(object.date).format(
                                          'YYYY-MM-DD',
                                        ),
                                    )
                                  ) {
                                    newDate = true
                                    datesWrittenArray.push(
                                      moment(object.date).format('YYYY-MM-DD'),
                                    )
                                  }
                                  return (
                                    <>
                                      {newDate && (
                                        <div className="col-span-2">
                                          <div className="w-full flex flex-1 font-bold text-sm">
                                            {getDaysFullForTranslatation(
                                              moment(object.date).day(),
                                            )}{' '}
                                            {moment(object.date).format(
                                              'DD/MM',
                                            )}
                                          </div>
                                        </div>
                                      )}
                                      <KidBookingCard
                                        separateBooking={
                                          detailedInfoFromGroupInfoId.separateBookingKidIds?.find(
                                            (kidId) => kidId === object.kidId,
                                          )
                                            ? true
                                            : false
                                        }
                                        object={object}
                                      />
                                    </>
                                  )
                                })}
                            </div>
                            <div className="sticky h-[6vh] bg-gradient-to-t from-white to-[rgba(255,255,255,0)] bottom-0 left-0 w-full"></div>
                          </>
                        )}
                        {activeTab.value === 'free_times' && (
                          <>
                            <div className="w-full text-center font-bold p-4">
                              {t('events.availableTimes')}
                            </div>
                            <div className="grid grid-cols-2 w-full gap-3">
                              {detailedInfoFromGroupInfoId?.bookingOverview?.freeTimes
                                .slice()
                                .sort((a, b) => {
                                  const dateA = new Date(a.date!)
                                  const dateB = new Date(b.date!)

                                  if (dateA.getTime() !== dateB.getTime()) {
                                    return dateA.getTime() - dateB.getTime()
                                  }

                                  const [startHourA, startMinuteA] = a
                                    .startTime!.split(':')
                                    .map(Number)
                                  const [startHourB, startMinuteB] = b
                                    .startTime!.split(':')
                                    .map(Number)

                                  const timeA = new Date(dateA)
                                  timeA.setHours(startHourA, startMinuteA)

                                  const timeB = new Date(dateB)
                                  timeB.setHours(startHourB, startMinuteB)

                                  return timeA.getTime() - timeB.getTime()
                                })
                                .map((object) => {
                                  let newMonth = false
                                  if (
                                    !monthsWrittenArray.find(
                                      (date) =>
                                        date ===
                                        moment(object.date).format('YYYY-MM'),
                                    )
                                  ) {
                                    newMonth = true
                                    monthsWrittenArray.push(
                                      moment(object.date).format('YYYY-MM'),
                                    )
                                  }
                                  return (
                                    <>
                                      {newMonth && (
                                        <>
                                          <div className="w-full font-bold text-sm col-span-2">
                                            {getFullMonthForTranslatation(
                                              moment(object.date).format('MM'),
                                            )}
                                          </div>
                                        </>
                                      )}
                                      <KidBookingCard
                                        separateBooking={
                                          detailedInfoFromGroupInfoId.separateBookingKidIds?.find(
                                            (kidId) => kidId === object.kidId,
                                          )
                                            ? true
                                            : false
                                        }
                                        object={object}
                                        onBook={() => {
                                          setChooseKid(true)
                                        }}
                                      />
                                    </>
                                  )
                                })}
                            </div>
                            <div className="sticky h-[6vh] bg-gradient-to-t from-white to-[rgba(255,255,255,0)] bottom-0 left-0 w-full"></div>
                          </>
                        )}
                        {activeTab.value === 'not_booked' && (
                          <div className="relative">
                            <div className="w-full text-center font-bold p-4">
                              {t('events.notBooked')}
                            </div>
                            <div className="grid grid-cols-2 w-full gap-3 ">
                              {detailedInfoFromGroupInfoId?.bookingOverview?.kidsNotBooked
                                .slice()
                                .sort((a, b) => {
                                  return a.name!.localeCompare(b.name!)
                                })
                                .map((object) => {
                                  return (
                                    <KidBookingCard
                                      separateBooking={
                                        detailedInfoFromGroupInfoId.separateBookingKidIds?.find(
                                          (kidId) => kidId === object.kidId,
                                        )
                                          ? true
                                          : false
                                      }
                                      object={object}
                                      onBook={() => {
                                        const kid = kids?.find(
                                          (kid) => kid.id === object.kidId,
                                        )
                                        if (kid) {
                                          setChooseTime(true)
                                          setSelectedKid(kid)
                                        } else {
                                          // !!SWAL Error
                                        }
                                      }}
                                      onRemind={() => {
                                        if (object.kidId) {
                                          sendReminder(
                                            undefined,
                                            undefined,
                                            showSpecificMeeting,
                                            [object.kidId],
                                          )
                                            .catch(() => {})
                                            .then(() => {
                                              setReminderSentTo([
                                                ...reminderSentTo,
                                                object.kidId!,
                                              ])
                                            })
                                        }
                                      }}
                                      reminderSent={
                                        reminderSentTo.find(
                                          (rST) => rST === object.kidId,
                                        )
                                          ? true
                                          : false
                                      }
                                    />
                                  )
                                })}
                            </div>
                            <div className="sticky h-[5vh] bg-gradient-to-t from-white to-[rgba(255,255,255,0)] bottom-0 left-0 w-full"></div>
                          </div>
                        )}
                        {activeTab.value === 'messages' && (
                          <>
                            {detailedInfoFromGroupInfoId?.bookingOverview
                              ?.messages.length === 0 ? (
                              <div className="w-full h-full flex items-center justify-center flex-col">
                                <FontAwesomeIcon
                                  className="text-purpleLight"
                                  icon={faCalendarDay}
                                  size="8x"
                                />
                                <p className="pt-4 font-semibold">
                                  Nothing to show
                                </p>
                              </div>
                            ) : (
                              <>
                                <div className="w-full text-center font-bold p-4">
                                  {t('blog.comments')}
                                </div>
                                <div className="grid grid-cols-2 w-full gap-3">
                                  {detailedInfoFromGroupInfoId?.bookingOverview?.messages.map(
                                    (object) => {
                                      return (
                                        <KidBookingCard
                                          separateBooking={
                                            detailedInfoFromGroupInfoId.separateBookingKidIds?.find(
                                              (kidId) => kidId === object.kidId,
                                            )
                                              ? true
                                              : false
                                          }
                                          object={object}
                                        />
                                      )
                                    },
                                  )}
                                  <div className="sticky h-[5vh] bg-gradient-to-t from-white to-[rgba(255,255,255,0)] bottom-0 left-0 w-full"></div>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {detailedInfoFromGroupInfoId?.times?.length ? (
                          <FreeTimesCard
                            selectedTime={selectedTime}
                            onClick={(value) => setSelectedTime(value)}
                            times={detailedInfoFromGroupInfoId.times}
                          />
                        ) : (
                          <>Inga tider</>
                        )}
                        <div className="flex w-full justify-center mt-8">
                          <Button
                            size="sm"
                            className="w-[50%]"
                            variant="fullBlue"
                            disabled={selectedTime ? false : true}
                            label={t('events.book')}
                            onClick={() => {
                              setShowFinalStage(true)
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="grid grid-cols-2 gap-4 w-full">
                    {listToBookMeeting?.map((meeting) => {
                      return (
                        <MeetingsCard
                          eventInfo={meeting}
                          onClick={async () => {
                            setLoading(true)
                            setShowSpecificMeeting(meeting.groupInfoId)
                            await getDetailedInfoFromGroupInfoId(
                              meeting.groupInfoId,
                            ).finally(() => {
                              setLoading(false)
                            })
                          }}
                          isStaff={isStaff}
                        />
                      )
                    })}
                  </div>
                </>
              )}
            </>
          )}
        </div>
        {chooseKid && (
          <ModalRegular
            isOpen={chooseKid}
            onClose={() => {
              setChooseKid(false)
              setSelectedKid(undefined)
            }}
            hideCloseButton
            className="max-w-[700px]"
          >
            <div className="p-6">
              <ChooseKids
                onChange={(kidId) => {
                  if (selectedKid?.id === kidId) {
                    setSelectedKid(undefined)
                  } else {
                    setSelectedKid(kids?.find((kid) => kid.id === kidId))
                  }
                }}
                selectedKidIds={selectedKid ? [selectedKid.id] : []}
                departments={
                  departments?.filter((dep) =>
                    detailedInfoFromGroupInfoId?.departmentIds.includes(dep.id),
                  ) ?? []
                }
                kids={kids?.filter((kid) =>
                  detailedInfoFromGroupInfoId?.bookingOverview?.kidsNotBooked.find(
                    (kidId) => kidId.kidId === kid.id,
                  ),
                )}
                /*showOnlyKidIds={
                  detailedInfoFromGroupInfoId?.bookingOverview?.kidsNotBooked
                    .length
                    ? detailedInfoFromGroupInfoId?.bookingOverview?.kidsNotBooked.map(
                        (kNB) => {
                          return kNB.kidId!
                        },
                      )
                    : []
                }*/
              />
              {selectedKid && (
                <div className="py-3 h-[7vh] sticky bottom-0 left-0 flex w-full justify-center mt-8 bg-gradient-to-t from-white ">
                  <Button
                    size="sm"
                    className="w-[50%]"
                    variant="fullBlue"
                    label={t('general.done')}
                    onClick={() => {
                      setChooseTime(true)
                      setChooseKid(false)
                    }}
                  />
                </div>
              )}
            </div>
          </ModalRegular>
        )}
        {chooseTime && selectedKid && (
          <>
            {showFinalStageStaff ? (
              <BookingFinalStage
                isOpen={showFinalStageStaff}
                onClose={() => {
                  setShowFinalStageStaff(false)
                  setSelectedTime(null)
                  setPresentGuardians([])
                  setChooseTime(false)
                  setSelectedKid(undefined)
                }}
                kidName={
                  isStaff
                    ? selectedKid?.firstName + ' ' + selectedKid?.lastName
                    : currentUserRole?.kid?.firstName +
                      ' ' +
                      currentUserRole?.kid?.lastName
                }
                selectedTime={selectedTime}
                isStaff={isStaff ? true : false}
                message={message}
                setMessage={(message) => setMessage(message)}
                guardians={guardians}
                presentGuardians={presentGuardians}
                onGuardianClick={(guardian: UserRole) => {
                  if (presentGuardians.find((pG) => pG.id === guardian.id)) {
                    setPresentGuardians(
                      presentGuardians.filter((pG) => pG.id !== guardian.id),
                    )
                  } else {
                    setPresentGuardians([...presentGuardians, guardian])
                  }
                }}
                onSave={async () => {
                  if (selectedTime && selectedKid && showSpecificMeeting) {
                    setLoading(true)
                    await acceptParticipatingInEvent(
                      selectedTime.id,
                      undefined,
                      [selectedKid.id],
                      message,
                      undefined,
                      true,
                      presentGuardians.map((pG) => pG.id),
                    )
                      .catch((e) => {
                        Swal.fire({
                          //title: t('general.accountNotAvailible'),
                          text: t('events.alreadyBooked'),
                          icon: 'warning',
                          showCancelButton: false,
                          focusConfirm: false,
                          confirmButtonColor: '#7eb5f4',
                          cancelButtonColor: '#d33',
                          confirmButtonText: t('general.ok'),
                        })
                        setLoading(false)
                        throw new Error()
                        //!! SWAL HÄR
                      })
                      .then(async () => {
                        await getEvents()
                          .catch((e) => {
                            throw new Error()
                            //!! SWAL HÄR
                          })
                          .finally(async () => {
                            await getDetailedInfoFromGroupInfoId(
                              showSpecificMeeting,
                            )
                              .catch((e) => {
                                throw new Error()
                                //!! SWAL HÄR
                              })
                              .then(() => {
                                setShowFinalStageStaff(false)
                                //setShowSpecificMeeting(null)
                                setSelectedTime(null)
                                setPresentGuardians([])
                                setChooseTime(false)
                                setSelectedKid(undefined)
                                //onClose()
                              })
                          })
                          .finally(() => {
                            setLoading(false)
                          })
                      })
                  }
                }}
              />
            ) : (
              <ModalRegular
                isOpen={chooseTime}
                onClose={() => {
                  setChooseTime(false)
                  setSelectedKid(undefined)
                  setSelectedTime(null)
                }}
                hideCloseButton
                className="max-w-[400px]"
              >
                <div className="p-6">
                  <div className="flex w-full text-center font-bold text-lg justify-center">
                    {t('events.chooseATime')}
                  </div>
                  <div className="flex w-full text-center font-bold text-sm justify-center mt-2">
                    {selectedKid?.firstName + ' ' + selectedKid?.lastName}
                  </div>
                  {detailedInfoFromGroupInfoId?.times?.length ? (
                    <FreeTimesCard
                      selectedTime={selectedTime}
                      onClick={(value) => setSelectedTime(value)}
                      times={detailedInfoFromGroupInfoId.times}
                    />
                  ) : (
                    <>Inga tider</>
                  )}
                  <div className="flex justify-between text-xs mt-6">
                    <Button
                      label={t('general.cancel')}
                      variant="fullWhiteGrayText"
                      onClick={() => {
                        setChooseTime(false)
                        setSelectedKid(undefined)
                        setSelectedTime(null)
                      }}
                    />
                    <Button
                      label={t('events.confirm')}
                      variant="fullBlue"
                      disabled={selectedTime ? false : true}
                      onClick={() => {
                        setShowFinalStageStaff(true)
                      }}
                    />
                  </div>
                </div>
              </ModalRegular>
            )}
          </>
        )}
        {showFinalStage && (
          <>
            <BookingFinalStage
              isOpen={showFinalStage}
              onClose={() => setShowFinalStage(false)}
              kidName={
                isStaff
                  ? selectedKid?.firstName + ' ' + selectedKid?.lastName
                  : currentUserRole?.kid?.firstName +
                    ' ' +
                    currentUserRole?.kid?.lastName
              }
              selectedTime={selectedTime}
              isStaff={isStaff ? true : false}
              message={message}
              setMessage={(message) => setMessage(message)}
              guardians={guardians}
              presentGuardians={presentGuardians}
              onGuardianClick={(guardian: UserRole) => {
                if (presentGuardians.find((pG) => pG.id === guardian.id)) {
                  setPresentGuardians(
                    presentGuardians.filter((pG) => pG.id !== guardian.id),
                  )
                } else {
                  setPresentGuardians([...presentGuardians, guardian])
                }
              }}
              onSave={async () => {
                if (selectedTime && currentUserRole && currentUserRole.kid) {
                  setLoading(true)
                  await acceptParticipatingInEvent(
                    selectedTime.id,
                    undefined,
                    [currentUserRole?.kid.id],
                    message,
                    undefined,
                    true,
                    presentGuardians.map((pG) => pG.id),
                  )
                    .catch((e) => {
                      Swal.fire({
                        //title: t('general.accountNotAvailible'),
                        text: t('events.alreadyBooked'),
                        icon: 'warning',
                        showCancelButton: false,
                        focusConfirm: false,
                        confirmButtonColor: '#7eb5f4',
                        cancelButtonColor: '#d33',
                        confirmButtonText: t('general.ok'),
                      })
                      //throw new Error()
                      //!! SWAL HÄR
                    })
                    .then(async () => {
                      await getEvents()
                        .catch((e) => {
                          throw new Error()
                          //!! SWAL HÄR
                        })
                        .finally(async () => {
                          await getListToBookMeeting()
                            .catch((e) => {
                              throw new Error()
                              //!! SWAL HÄR
                            })
                            .then(() => {
                              setShowFinalStage(false)
                              setShowSpecificMeeting(null)
                              setSelectedTime(null)
                              setPresentGuardians([])
                              onClose()
                            })
                        })
                        .finally(() => {
                          setLoading(false)
                        })
                    })
                }
              }}
            />
          </>
        )}
      </>
    )
  },
)
