import { observer } from 'mobx-react'
import Page from '../../components/Page'
import classNames from 'classnames'
import { Button } from '../../components/Button'
import { Events3 } from './Events3'
import { useTranslation } from 'react-i18next'
import { Calendar } from '../../components/Calendar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV, faList } from '@fortawesome/free-solid-svg-icons'
import { useContext, useEffect, useState } from 'react'
import { Event3StoreContext } from '../../store/event3Store'
import { Moment } from 'moment'
import { Loading } from '../../components/Loading'
import { useNavigate, useParams } from 'react-router-dom'
import { UserStoreContext } from '../../store/userStore'
import { USER_ROLE_TYPE } from '../../Enums/TyraEnums'
import ModalRegular from '../../components/ModalRegular'
import { MeetingsToBook } from './components/MeetingsToBook'
import {
  faEnvelopeDot,
  faMemoCircleCheck,
} from '@fortawesome/pro-solid-svg-icons'
import moment from 'moment'

export const EventsStartPage: React.FC = observer(() => {
  const { t } = useTranslation()
  const [showUnread, setShowUnread] = useState<boolean>(false)
  const [animate, setAnimate] = useState(false)
  const { schoolId } = useParams()
  //const [currentMonth, setCurrentMonth] = useState<Moment>(moment())
  const {
    createEvent,
    getEvents,
    events,
    meetingsToBook,
    currentMeetings,
    getDetailedEvent,
    currentMonth,
    changeCurrentMonth,
    eventsLoading,
  } = useContext(Event3StoreContext)

  const { currentUserRole } = useContext(UserStoreContext)

  const { changeSelectedCalendarDate, selectedCalendarDate, setEventsAsRead } =
    useContext(Event3StoreContext)
  const navigate = useNavigate()

  const handleShowUnread = () => {
    setShowUnread(!showUnread)
  }
  const handleShowAll = () => {
    setShowUnread(!showUnread)
  }

  const [openMeetingsToBook, setOpenMeetingsToBook] = useState(false)

  useEffect(() => {
    if (showUnread) {
      // Start animation when showUnread becomes true
      setAnimate(true)
    } else {
      // Stop animation when showUnread becomes false
      setAnimate(false)
    }
  }, [showUnread, events])

  const closestEvent = events?.reduce((closest, event) => {
    const today = moment()
    const closestDiff = Math.abs(moment(closest.eventDate).diff(today))
    const eventDiff = Math.abs(moment(event.eventDate).diff(today))

    return eventDiff < closestDiff ? event : closest
  })

  useEffect(() => {
    getEvents()
  }, [currentMonth])

  return (
    <Page>
      <div className="flex flex-col h-[100%]">
        <div
          className={classNames(
            `w-full py-4 text-white bg-[#7eb5f4] flex justify-between items-center`,
          )}
        >
          <div
            className="w-1/4
            "
          ></div>
          <div className=" flex w-2/4 justify-center text-2xl font-bold">
            {t('menu.events')}
          </div>
          <div className="flex justify-end w-1/4 items-center pr-3">
            {currentUserRole &&
              (currentUserRole.role_type >= USER_ROLE_TYPE.TEACHER ||
                currentUserRole.is_upload_document_parent === true) && (
                <div className="pr-4">
                  <Button
                    variant="custom"
                    textColor="7eb5f4"
                    size="sm"
                    fontSize="font-semibold"
                    label={t('events.eventCreate') + ' +'}
                    onClick={() => {
                      navigate('/events/create/' + schoolId)
                    }}
                  />
                </div>
              )}
            {/* This will be used later dont remove */}
            {/* <FontAwesomeIcon
              icon={faEllipsisV}
              size="xl"
              className="text-gray-100 cursor-pointer"
              onClick={() => console.log('To be fixed...')}
            /> */}
          </div>
        </div>
        {currentMeetings && currentMeetings.length ? (
          <div
            className="flex bg-purpleChildren sticky top-0 p-3 justify-center font-bold text-sm text-white cursor-pointer"
            onClick={() => setOpenMeetingsToBook(true)}
          >
            <div>
              {t('events.booking')} ({currentMeetings.length})
            </div>
          </div>
        ) : null}
        {meetingsToBook && meetingsToBook.length ? (
          <>
            <div className="flex bg-purpleChildren sticky top-0 p-3 justify-center font-bold text-sm text-white cursor-pointer">
              <div
                onClick={() => {
                  setOpenMeetingsToBook(true)
                }}
              >
                {t('events.booking')} ({meetingsToBook.length})
              </div>
            </div>
          </>
        ) : null}
        {openMeetingsToBook && (
          <ModalRegular
            isOpen={openMeetingsToBook}
            onClose={() => setOpenMeetingsToBook(false)}
            hideCloseButton
            className="max-w-[1000px]"
          >
            <MeetingsToBook onClose={() => setOpenMeetingsToBook(false)} />
          </ModalRegular>
        )}
        <div className="flex flex-col md:flex-row flex-1 overflow-hidden">
          <div className="w-full md:w-2/5 md:h-full h-1/3  overflow-auto scrollbar-hide pl-1">
            <Calendar
              multiDates={false}
              changeMonth={async (value: Moment) => {
                await changeCurrentMonth(value)
              }}
              onChangeEvent={() => console.log('')}
              onDateClick={(value, lastDate) => {
                changeSelectedCalendarDate(
                  lastDate === selectedCalendarDate ? undefined : lastDate,
                )
              }}
              dates={selectedCalendarDate ? [selectedCalendarDate] : []}
              currentMonth={currentMonth}
            />
            <div className="flex flex-col flex-wrap mt-1 items-end justify-around gap-y-4 p-2 mr-1 transition-all duration-500 ease-in-out">
              {
                <>
                  <Button
                    variant="custom"
                    size="sm"
                    textColor="black"
                    fontSize="font-semibold"
                    label={
                      showUnread ? t('general.showAll') : t('notes.showUnread')
                    }
                    icon={showUnread ? faList : faEnvelopeDot}
                    iconColor={showUnread ? 'text-black' : 'text-blueDark'}
                    onClick={() => handleShowAll()}
                  />
                  {events?.some((ev) => ev.isRead === false) && (
                    <div
                      className={`transform transition-all duration-500 ease-in-out ${
                        animate
                          ? 'translate-y-0 opacity-100'
                          : 'translate-y-[-20px] opacity-0'
                      }`}
                    >
                      <Button
                        variant="custom"
                        textColor="black"
                        size="sm"
                        fontSize="font-semibold"
                        label={t('events.setAsRead')}
                        icon={faMemoCircleCheck}
                        iconColor="text-greenForrest"
                        onClick={() => {
                          setEventsAsRead()
                          handleShowUnread()
                        }}
                      />
                    </div>
                  )}
                </>
              }
            </div>
          </div>
          <div className="w-full md:w-3/5 md:h-full h-2/3 md:overflow-y-scroll overflow-y-scroll scrollbar-hide pr-2">
            {eventsLoading ? (
              <Loading />
            ) : (
              <Events3
                showUnread={showUnread}
                scrollToEventId={closestEvent?.eventDate!}
              />
            )}{' '}
          </div>
        </div>
      </div>
    </Page>
  )
})
