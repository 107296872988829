import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  BookingOverviewObject,
  BookingOverviewObjectType,
  EVENT3_TYPE,
  EventTimeSmallInterface,
} from '../../../store/event3Store'
import moment from 'moment'
import { CalendarDateIcon } from '../../../components/CalendarDateIcon'
import { Button } from '../../../components/Button'
import { getDaysFullForTranslatation } from '../../events/components/EventsInfo'
import ModalRegular from '../../../components/ModalRegular'
import classNames from 'classnames'
import { UserRole } from '../../../Models/UserRole'
import { getImageOrPlaceHolder } from '../../../utils/parseUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

interface Props {
  isOpen: boolean
  onClose: () => void
  onSave: () => void
  kidName: string
  selectedTime: EventTimeSmallInterface | null
  message: string
  setMessage: (value: string) => void
  guardians: UserRole[] | undefined
  presentGuardians: UserRole[]
  onGuardianClick: (guardian: UserRole) => void
  isStaff: boolean
}

export const BookingFinalStage: React.FC<Props> = ({
  isOpen,
  onClose,
  onSave,
  kidName,
  selectedTime,
  message,
  setMessage,
  guardians,
  presentGuardians,
  onGuardianClick,
  isStaff,
}) => {
  const { t } = useTranslation()

  const [wave, setWave] = useState(false)
  const triggerWave = () => {
    setWave(true)
    setTimeout(() => {
      setWave(false)
    }, 1000)
  }

  return (
    <ModalRegular
      isOpen={isOpen}
      onClose={() => onClose()}
      hideCloseButton
      className="max-w-[400px]"
    >
      <div className="p-6">
        <div className="flex w-full text-center font-bold text-lg justify-center">
          {t('events.confirmBooking')}
        </div>
        <div className="flex w-full text-center font-bold text-sm justify-center mt-2">
          {kidName}
        </div>
        <div className="flex w-full text-center text-sm justify-center">
          {selectedTime?.start_time}-{selectedTime?.end_time},{' '}
          {getDaysFullForTranslatation(moment(selectedTime?.start_date).day())}{' '}
          {moment(selectedTime?.start_date).format('DD/MM YYYY')}
        </div>
        <div className="flex w-full text-center text-sm justify-center mt-4">
          <textarea
            className="w-full min-h-[120px] block px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-xl shadow appearance-none focus:outline-none focus:ring-eventsMain focus:border-eventsMain sm:text-sm"
            placeholder={t('events.leaveAMessage')}
            onChange={(e) => setMessage(e.target.value)}
          >
            {message}
          </textarea>
        </div>
        <div className="flex w-full font-bold text-sm mt-4">
          {t('events.present')}:
        </div>
        <div
          className={classNames(
            'flex w-full font-bold text-sm mt-4 flex-col gap-y-3',
            wave ? 'animate-shake' : '',
          )}
        >
          {guardians?.map((userRole) => {
            let name = ''
            let isChecked = presentGuardians.find((pG) => pG.id === userRole.id)
            if (userRole.alternative_contact) {
              name =
                userRole.alternative_contact.attributes.first_name +
                ' ' +
                userRole.alternative_contact.attributes.last_name
            } else {
              name = userRole.user?.firstName + ' ' + userRole.user?.lastName
            }
            return (
              <div
                onClick={() => {
                  onGuardianClick(userRole)
                }}
                className="w-full flex justify-between items-center cursor-pointer"
              >
                <div className="flex items-center gap-x-3">
                  <img
                    className={'w-[30px] h-[30px] rounded-full border '}
                    src={getImageOrPlaceHolder(
                      userRole.alternative_contact
                        ? userRole.alternative_contact.attributes?.picture_thumb
                            ?._url
                        : userRole.user?.attributes?.myPic_thumb?._url,
                    )}
                    alt=""
                  />
                  <div>{name}</div>
                </div>
                <div
                  className={`w-6 h-6 rounded-full border flex justify-center items-center ${
                    isChecked
                      ? 'border-transparent bg-purple-400'
                      : 'border-gray-400 transparent'
                  }`}
                >
                  {isChecked && (
                    <FontAwesomeIcon icon={faCheck} color="#ffffff" />
                  )}
                </div>
              </div>
            )
          })}
          <div className="flex justify-between">
            <Button
              label={t('general.cancel')}
              variant="fullWhiteGrayText"
              onClick={() => onClose()}
            />
            <Button
              label={t('events.confirm')}
              variant="fullBlue"
              onClick={async () => {
                if (!isStaff && guardians?.length && !presentGuardians.length) {
                  triggerWave()
                } else {
                  onSave()
                }
              }}
            />
          </div>
        </div>
      </div>
    </ModalRegular>
  )
}
