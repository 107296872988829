import React, { useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { Button } from '../../../components/Button'
import ModalRegular from '../../../components/ModalRegular'
import { ActiveUsers, DocumentStoreContext } from '../../../store/documentStore'
import { UserStoreContext } from '../../../store/userStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import { UserRole } from '../../../Models/UserRole'
import { User } from '../../../Models/User'
import {
  NotificationContext,
  NotificationVariant,
} from '../../../App/notification/notificationManger'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'
import { Loading } from '../../../components/Loading'
import { USER_ROLE_TYPE } from '../../../Enums/TyraEnums'
import { CreateEventParams } from '../../../store/event3Store'
import { TabHeaderSlider } from '../../documents/DocumentMain'
import { Kid } from '../../../Models/Kid'
import { getImageFromRole } from '../../../utils/parseUtils'
import { DepartmentStoreContext } from '../../../store/departmentStore'

const findRoles = (items: any, search: string) => {
  const text = search.split(' ')
  return items.filter((item: any) => {
    return text.every((el) => {
      return (
        item?.user?.attributes?.firstName.toLowerCase().includes(el) ||
        item?.user?.attributes?.lastName.toLowerCase().includes(el)
      )
    })
  })
}

const findKids = (items: any, search: string) => {
  const text = search.split(' ')
  return items.filter((item: any) => {
    return text.every((el) => {
      return (
        item?.kid.attributes?.firstName.toLowerCase().includes(el) ||
        item?.kid.attributes?.lastName.toLowerCase().includes(el)
      )
    })
  })
}

export const SpecificPersonModal: React.FC<{
  onSaved: any
  newEvent: CreateEventParams
  onClose: () => void
}> = observer(({ onSaved, newEvent, onClose }) => {
  const { t } = useTranslation()
  const {
    getSchoolActiveUsers,
    schoolsActiveUsers,
    shareDocument,
    activeDocument,
    setActiveDocument,
    getDoc,
  } = useContext(DocumentStoreContext)
  const { notify } = useContext(NotificationContext)
  const [loading, setLoading] = useState(false)
  const { savedUserRoleId, currentUser } = useContext(UserStoreContext)
  const { departmentsForMultiSchool } = useContext(DepartmentStoreContext)
  const [showModal, setShowModal] = useState(false)
  const [personal, setPersonal] = useState(true)
  const [parent, setParent] = useState(true)
  const [selectedUserRoleIds, setSelectedUserRoleIds] = useState<any>(
    newEvent.specificUserRoleIds ?? [],
  )
  const [selectedUsers, setSelectedUsers] = useState<any>([])
  const [initialSelectedUsers, setInitialSelectedUsers] = useState<any>(
    newEvent.specificUserRoleIds ?? [],
  )
  const [searchActiveUsers, setSearchActiveUsers] =
    useState<ActiveUsers | null>()
  const [clicked, setClicked] = useState(false)
  const [activeTab, setActiveTab] = useState<{
    label: string
    value: string
    color?: string
  }>({
    label: t('general.kids'),
    value: 'kids',
  })

  const search = (searchText: string) => {
    const roles = findRoles(schoolsActiveUsers?.userRoles, searchText)
    const kids = findKids(schoolsActiveUsers?.kids, searchText)
    if (searchText?.length > 0) {
      setSearchActiveUsers({ userRoles: roles, kids: kids })
    } else {
      setSearchActiveUsers(null)
    }
  }
  useEffect(() => {
    if (savedUserRoleId) {
      setLoading(true)
      getSchoolActiveUsers(savedUserRoleId).finally(() => {
        setLoading(false)
      })
    }
    return () => {
      setActiveDocument(null)
      setInitialSelectedUsers([])
      setSelectedUsers([])
      setClicked(false)
    }
  }, [savedUserRoleId])

  useEffect(() => {}, [schoolsActiveUsers])

  /*useEffect(() => {
    getDoc(documentId)

    return () => {
      setActiveDocument(null)
      setInitialSelectedUsers([])
      setSelectedUsers([])
      setClicked(false)
    }
  }, [documentId])*/

  useEffect(() => {
    if (activeDocument) {
      setInitialSelectedUsers(
        activeDocument?.attributes?.user_shared_with ?? [],
      )
    }
  }, [activeDocument])

  useEffect(() => {}, [activeTab])

  /*  useEffect(() => {
    if (clicked) {
      setLoading(true)
      const selected = [...initialSelectedUsers, ...selectedUsers]
      const userIds = selected.map((u: any) => u.id)
      shareDocument(userIds, documentId).finally(() => {
        setLoading(false)
      })
    }
  }, [selectedUsers, clicked])*/

  const items = searchActiveUsers ? searchActiveUsers : schoolsActiveUsers

  console.log('items', items)

  return (
    <div className={'w-full'}>
      <ModalRegular
        title={'!!Välj mottagare'}
        isOpen={true}
        closeColor="white"
        className={'!max-w-xl p-4'}
        classNameTitle="mb-4 w-full text-black !important flex justify-center font-semibold"
        onClose={() => {
          onClose()
        }}
      >
        {loading && <Loading />}
        <TabHeaderSlider
          tabs={[
            {
              label: t('general.kids'),
              value: 'kids',
            },
            {
              label: t('general.guardian'),
              value: 'guardians',
            },
            {
              label: t('documents.staff'),
              value: 'staff',
            },
          ]}
          onClick={(value) => {
            setActiveTab(value)
          }}
          activeTab={activeTab}
        />
        <div className="p-5">
          <div className={'mt-2 mb-4 flex flex-col gap-y-7'}>
            <div className="flex flex-wrap w-full justify-center items-center">
              <SearchInput
                onChange={(s: string) => {
                  search(s)
                }}
              />
            </div>
          </div>

          <div className={'grid grid-cols-1 gap-2'}>
            {activeTab.value === 'kids' && (
              <>
                {departmentsForMultiSchool?.map((department) => {
                  return (
                    <>
                      <div>{department.klassName}</div>
                      <div className="grid grid-cols-2 flex-wrap gap-2">
                        {items?.kids
                          ?.filter(
                            (kid) =>
                              kid.kid.departmentPointer?.id === department.id,
                          )
                          ?.map((kid, index) => {
                            if (!parent) {
                              return null
                            }

                            return (
                              <KidItem
                                key={index}
                                kid={kid}
                                selectedUserRoleIds={selectedUserRoleIds}
                                guardianClick={(userRoleId: string) => {
                                  if (
                                    selectedUserRoleIds.find(
                                      (id: string) => id === userRoleId,
                                    )
                                  ) {
                                    setSelectedUserRoleIds(
                                      selectedUserRoleIds.filter(
                                        (id: string) => id !== userRoleId,
                                      ),
                                    )
                                  } else {
                                    setSelectedUserRoleIds([
                                      ...selectedUserRoleIds,
                                      userRoleId,
                                    ])
                                  }
                                }}
                                onClick={() => {
                                  const ids = kid?.userRoles?.map(
                                    (uR: any) => uR?.id,
                                  )
                                  if (
                                    selectedUserRoleIds.filter((id: string) =>
                                      ids.includes(id),
                                    )?.length > 0
                                  ) {
                                    setSelectedUserRoleIds(
                                      selectedUserRoleIds.filter(
                                        (id: string) => !ids.includes(id),
                                      ),
                                    )
                                  } else {
                                    const userRoleIds = kid.userRoles?.map(
                                      (uR) => uR.id,
                                    )
                                    setSelectedUserRoleIds([
                                      ...selectedUserRoleIds,
                                      ...userRoleIds,
                                    ])
                                  }
                                }}
                              />
                            )
                          })}
                      </div>
                    </>
                  )
                })}
              </>
            )}
            {activeTab.value === 'guardians' && (
              <div className="grid grid-cols-2 flex-wrap gap-2">
                {items?.userRoles?.map((userRole, index) => {
                  /*if (!parent) {
                    return null
                  }*/

                  return (
                    <GuardianItem
                      key={index}
                      kids={items.kids?.filter((kid) =>
                        kid.userRoles.find(
                          (uR) => uR?.user?.id === userRole.user?.id,
                        ),
                      )}
                      userRole={userRole}
                      selectedUserRoleIds={selectedUserRoleIds}
                      guardianClick={(userRoleId: string) => {
                        if (
                          selectedUserRoleIds.find(
                            (id: string) => id === userRoleId,
                          )
                        ) {
                          setSelectedUserRoleIds(
                            selectedUserRoleIds.filter(
                              (id: string) => id !== userRoleId,
                            ),
                          )
                        } else {
                          setSelectedUserRoleIds([
                            ...selectedUserRoleIds,
                            userRoleId,
                          ])
                        }
                      }}
                      onClick={() => {
                        const kids = items.kids?.filter((kid) =>
                          kid.userRoles.find(
                            (uR) => uR?.user?.id === userRole.user?.id,
                          ),
                        )

                        let ids: string[] = []

                        if (kids) {
                          for (const kid of kids as any) {
                            let kidUserRole = kid.userRoles.find(
                              (uR: any) => uR.attributes.kid.id === kid.kid.id,
                            )
                            if (kidUserRole) {
                              ids.push(kidUserRole.id)
                            }
                          }
                        }

                        if (
                          selectedUserRoleIds.filter((id: string) =>
                            ids.includes(id),
                          )?.length > 0
                        ) {
                          setSelectedUserRoleIds(
                            selectedUserRoleIds.filter(
                              (id: string) => !ids.includes(id),
                            ),
                          )
                        } else {
                          setSelectedUserRoleIds([
                            ...selectedUserRoleIds,
                            ...ids,
                          ])
                        }
                      }}
                    />
                  )
                })}
              </div>
            )}
            {activeTab.value === 'staff' && (
              <div className="grid grid-cols-4 flex-wrap gap-2">
                {items?.userRoles
                  ?.filter(
                    (userRole) => userRole.role_type > USER_ROLE_TYPE.PARENT,
                  )
                  ?.map((userRole, index) => {
                    return (
                      <UserRoleItem userRole={userRole} onClick={() => {}} />
                    )
                  })}
              </div>
            )}
          </div>

          {/*<div className={'flex w-full justify-end mt-4'}>
              <Button
                size="md"
                loading={loading}
                label={t('documents.save')}
                onClick={async () => {
                  setLoading(true)
                  const selected = [...initialSelectedUsers, ...selectedUsers]
                  const userIds = selected.map((u: any) => u.id)
                  await shareDocument(userIds, documentId).finally(() => {
                    setLoading(false)
                  })

                  
                  Swal.fire({
                    text: t('documents.sharedSuccessMessage'),
                    icon: 'success',
                    showCancelButton: false,
                    focusConfirm: false,
                    confirmButtonColor: '#7eb5f4',
                    cancelButtonColor: '#d33',
                    confirmButtonText: t('general.ok'),
                  })
                  setShowModal(false)
                  onSaved()
                }}
              />
            </div>*/}
        </div>
      </ModalRegular>
    </div>
  )
})

const SearchInput = ({ onChange }: { onChange: any }) => {
  const [text, setText] = useState('')
  const { t } = useTranslation()

  return (
    <input
      className={classNames(
        'block w-full px-6 py-4 placeholder-gray-400 border border-gray-300 rounded-full shadow appearance-none focus:outline-none focus:ring-eventsMain focus:border-eventsMain sm:text-sm',
      )}
      type={'text'}
      value={text}
      placeholder={t('documents.search') + '...'}
      onChange={(e) => {
        setText(e.target.value)
        onChange(e.target.value.toLowerCase())
      }}
    />
  )
}

const KidItem = ({
  kid,
  onClick,
  guardianClick,
  selectedUserRoleIds,
}: {
  kid: any
  onClick: any
  guardianClick: any
  selectedUserRoleIds?: string[]
}) => {
  const allRolesSelected = () => {
    for (const role of kid?.userRoles) {
      if (!selectedUserRoleIds?.find((id) => id === role?.id)) {
        return false
      }
    }
    return true
  }
  return (
    <div
      className={classNames(
        'flex border cursor-pointer rounded-xl',
        allRolesSelected() && 'border-blueDark',
      )}
      onClick={() => {
        onClick()
      }}
    >
      <div className={'flex flex-row gap-x-2 items-center p-3 relative w-full'}>
        <div className="flex flex-col w-[50px] gap-1 text-center">
          {kid?.kid?.attributes?.kidImage?._url ? (
            <img
              className={'w-[50px] h-[50px] rounded-full'}
              src={kid?.kid?.attributes?.kidImage?._url}
              alt=""
            />
          ) : (
            <div
              className={classNames(
                'w-[50px] h-[50px] bg-gray-200 rounded-full flex justify-center items-center',
                //allRolesSelected() && 'border border-blueDark',
              )}
            >
              <FontAwesomeIcon className={'text-gray-500 '} icon={faUser} />
            </div>
          )}
          <div className={'text-2xs text-center font-semibold flex-1'}>
            <p className={'whitespace-nowrap'}>
              {kid?.kid?.attributes?.firstName + ' '}
              <br />
              {kid?.kid?.attributes?.lastName}
            </p>
          </div>
        </div>

        <div className={'flex overflow-auto space-x-4'}>
          {kid?.userRoles?.map((role: UserRole, index: number) => {
            const isSelected = () => {
              return selectedUserRoleIds?.find((id) => id === role?.id)
            }
            return (
              <div
                key={index}
                className={classNames(
                  'flex flex-col justify-center items-center relative border p-2 rounded-xl',
                  isSelected() && 'border-blueDark bg-blue-50',
                )}
                onClick={(e) => {
                  e.stopPropagation()
                  guardianClick(role?.id)
                  console.log('HALLÅ 2')
                }}
              >
                {role?.user?.attributes?.myPic_thumb?._url ? (
                  <div className={'w-[40px] h-[40px]'}>
                    <img
                      className={'w-full h-full rounded-full'}
                      src={role?.user?.attributes?.myPic_thumb?._url}
                    />
                  </div>
                ) : (
                  <div
                    className={
                      'w-[40px] h-[40px]  bg-gray-200 rounded-full flex justify-center items-center'
                    }
                  >
                    <FontAwesomeIcon
                      className={'text-gray-500 '}
                      icon={faUser}
                    />
                  </div>
                )}
                <p className={'text-2xs text-center font-semibold'}>
                  {role?.user?.attributes?.firstName}
                  <br />
                  {role?.user?.attributes?.lastName}
                </p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const GuardianItem = ({
  kids,
  userRole,
  onClick,
  guardianClick,
  selectedUserRoleIds,
}: {
  kids: any
  userRole: UserRole
  onClick: any
  guardianClick: any
  selectedUserRoleIds?: string[]
}) => {
  const allRolesSelected = () => {
    for (const kid of kids) {
      let kidUserRole = kid.userRoles.find(
        (uR: any) => uR.attributes.kid.id === kid.kid.id,
      )
      console.log('kid', kid)
      console.log('kidUserRole', kidUserRole)
      if (!selectedUserRoleIds?.find((id) => id === kidUserRole?.id)) {
        return false
      }
    }
    return true
  }
  return (
    <div
      className={classNames(
        'flex border cursor-pointer rounded-xl',
        allRolesSelected() && 'border-blueDark',
      )}
      onClick={() => {
        onClick()
      }}
    >
      <div className={'flex flex-row gap-x-2 items-center p-3 relative w-full'}>
        <div className="flex flex-col w-[50px] gap-1 text-center">
          {userRole?.user?.attributes?.myPic?._url ? (
            <img
              className={'w-[50px] h-[50px] rounded-full'}
              src={userRole?.user?.attributes?.myPic?._url}
              alt=""
            />
          ) : (
            <div
              className={classNames(
                'w-[50px] h-[50px] bg-gray-200 rounded-full flex justify-center items-center',
                //allRolesSelected() && 'border border-blueDark',
              )}
            >
              <FontAwesomeIcon className={'text-gray-500 '} icon={faUser} />
            </div>
          )}
          <div className={'text-2xs text-center font-semibold flex-1'}>
            <p className={'whitespace-nowrap'}>
              {userRole?.user?.attributes?.firstName + ' '}
              <br />
              {userRole?.user?.attributes?.lastName}
            </p>
          </div>
        </div>

        <div className={'flex overflow-auto space-x-4'}>
          {kids?.map((kid: any, index: number) => {
            console.log('kid', kid)
            let kidUserRole = kid.userRoles.find(
              (uR: any) => uR.attributes.kid.id === kid.kid.id,
            )
            const isSelected = () => {
              return selectedUserRoleIds?.find((id) => id === kidUserRole?.id)
            }
            return (
              <div
                key={index}
                className={classNames(
                  'flex flex-col justify-center items-center relative border p-2 rounded-xl',
                  isSelected() && 'border-blueDark bg-blue-50',
                )}
                onClick={(e) => {
                  e.stopPropagation()
                  guardianClick(kidUserRole?.id)
                  console.log('HALLÅ 2')
                }}
              >
                {kid.kid?.attributes?.kidImage?._url ? (
                  <div className={'w-[40px] h-[40px]'}>
                    <img
                      className={'w-full h-full rounded-full'}
                      src={kid.kid?.attributes?.kidImage?._url}
                    />
                  </div>
                ) : (
                  <div
                    className={
                      'w-[40px] h-[40px]  bg-gray-200 rounded-full flex justify-center items-center'
                    }
                  >
                    <FontAwesomeIcon
                      className={'text-gray-500 '}
                      icon={faUser}
                    />
                  </div>
                )}
                <p className={'text-2xs text-center font-semibold'}>
                  {kid.kid?.attributes?.firstName + ' '}
                  <br />
                  {kid.kid?.attributes?.lastName}
                </p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

// Define the type for the hook's options
interface UseFitTextOptions {
  minFontSize?: number
  maxFontSize?: number
}

// Define the type for the return value of the hook
interface UseFitTextResult {
  ref: React.RefObject<HTMLDivElement>
  fontSize: number
}

const useFitText = ({
  minFontSize = 12,
  maxFontSize = 36,
}: UseFitTextOptions = {}): UseFitTextResult => {
  const ref = useRef<HTMLDivElement>(null)
  const [fontSize, setFontSize] = useState<number>(16)

  useEffect(() => {
    let animationFrameId: number

    const resizeObserver = new ResizeObserver((entries) => {
      animationFrameId = window.requestAnimationFrame(() => {
        const entry = entries[0]
        if (entry && ref.current) {
          const { width, height } = entry.contentRect
          // Calculate the new font size based on container size
          let newFontSize = Math.min(width / 10, height / 2)
          // Constrain the font size within min and max limits
          newFontSize = Math.max(
            minFontSize,
            Math.min(newFontSize, maxFontSize),
          )

          // Only update if the new font size is different to avoid unnecessary renders
          if (newFontSize !== fontSize) {
            setFontSize(newFontSize)
          }
        }
      })
    })

    if (ref.current) {
      resizeObserver.observe(ref.current)
    }

    // Clean up on unmount
    return () => {
      resizeObserver.disconnect()
      window.cancelAnimationFrame(animationFrameId)
    }
  }, [minFontSize, maxFontSize]) // Remove `fontSize` from dependencies

  return { ref, fontSize }
}

const UserRoleItem = ({
  userRole,
  onClick,
  selected,
}: {
  userRole: UserRole
  onClick: any
  selected?: boolean
}) => {
  const { ref, fontSize } = useFitText({ minFontSize: 8, maxFontSize: 32 })

  return (
    <div
      className={classNames(
        'p-2 w-full flex border hover:bg-blue-50 cursor-pointer rounded-xl',
        selected && 'border-blueDark bg-blue-50',
      )}
      onClick={() => onClick(selected)}
    >
      <div className="flex flex-col items-center p-3 relative w-full gap-y-2">
        <img
          className="w-[70px] h-[70px] rounded-full"
          src={getImageFromRole(userRole)}
          alt=""
        />

        <div
          ref={ref}
          style={{
            width: '100%',
            height: '50px',
            border: '1px solid black',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '18px',
          }}
        >
          <p
            style={{
              fontSize,
              margin: 0,
              display: 'inline-block',
              textAlign: 'center',
            }}
          >
            {userRole?.user?.attributes?.firstName + ' '}
            <br />
            {userRole?.user?.attributes?.lastName}
          </p>
        </div>
      </div>
    </div>
  )
}

const SelectedUserItem = ({
  user,
  onClick,
  selected,
}: {
  user: User
  onClick: any
  selected?: boolean
}) => {
  return (
    <div
      className={classNames(
        'h-[60px] flex border hover:bg-blue-50 cursor-pointer',
        selected && 'bg-green-50',
      )}
      onClick={() => {
        onClick(selected)
      }}
    >
      <div className={'flex  items-center p-3 relative w-full'}>
        {selected && (
          <FontAwesomeIcon
            className={'text-eventsMain absolute top-2 left-2 '}
            icon={faCheckCircle}
          />
        )}

        {user?.attributes?.myPic_thumb?._url ? (
          <img
            className={'w-[30px] h-[30px] rounded-full'}
            src={user?.attributes?.myPic_thumb?._url}
            alt=""
          />
        ) : (
          <div
            className={
              'w-[30px] h-[30px] bg-gray-200 rounded-full flex justify-center items-center'
            }
          >
            <FontAwesomeIcon className={'text-gray-500 '} icon={faUser} />
          </div>
        )}
        <div className={'text-sm ml-3 flex-1'}>
          <p className={'font-medium whitespace-nowrap'}>
            {user?.attributes?.firstName + ' '}
            {user?.attributes?.lastName}
          </p>
        </div>
      </div>
    </div>
  )
}
